import React from 'react'
import logo from '../Components/images/logo.png'
import icon4 from '../Components/images/icon/icon-4.png'
import payment from '../Components/images/payment.png'

function Footer() {
  return (
    <div className="section footer-section">
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    <div className="col-xl-2 col-md-4">
                        <div className="footer-logo">
                            <a href="index.html"><img src={logo} alt="Logo"/></a>
                        </div>
                    </div>
                    <div className="col-xl-5 col-md-8">
                        <div className="footer-contact-payment">
                            <div className="footer-contact">
                                <div className="contact-icon">
                                    <img src={icon4} alt="Icon"/>
                                </div>

                                <div className="contact-content">
                                    <h6 className="title">Call Us:</h6>
                                    <p>00 123 456 789</p>
                                </div>
                            </div>
                            <div className="footer-payment">
                                <img src={payment} alt="Payment"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 ">
                        <div className="footer-subscribe">
                            <span className="title">Subscribe Now :</span>
                            <div className="subscribe-form">
                                <form action="#">
                                    <input type="text" placeholder="Your Mail"/>
                                    <button><i className="pe-7s-paper-plane"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-widget-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Customer Service</h4>

                            <ul className="footer-link">
                                <li><a href="#!">Payment Methods</a></li>
                                <li><a href="#!">Money-back guarantee!</a></li>
                                <li><a href="#!">Returns-Shipping</a></li>
                                <li><a href="#!">Terms and conditions</a></li>
                                <li><a href="#!">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Information</h4>

                            <ul className="footer-link">
                                <li><a href="#!">About Furbar</a></li>
                                <li><a href="#!">How to Shop</a></li>
                                <li><a href="#!">FAQ</a></li>
                                <li><a href="#!">Contact us</a></li>
                                <li><a href="#!">Log in</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">My Account</h4>

                            <ul className="footer-link">
                                <li><a href="#!">Sign In</a></li>
                                <li><a href="#!">View Cart</a></li>
                                <li><a href="#!">My Wishlist</a></li>
                                <li><a href="#!">Track My Order</a></li>
                                <li><a href="#!">Help</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-6">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">Our Stores</h4>

                            <ul className="footer-link">
                                <li><a href="#!">New aYork</a></li>
                                <li><a href="#!">London SF</a></li>
                                <li><a href="#!">Cockfosters BP</a></li>
                                <li><a href="#!">Los Angeles</a></li>
                                <li><a href="#!">Chicago</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-7">
                        <div className="footer-widget">
                            <h4 className="footer-widget-title">About Us</h4>

                            <div className="widget-about">
                                <p>Lorem ipsum dolor sit amet consect adipisicing elit sed do eiusmod temp incididunt ut labore et dolore magna aliqua. Ut enim ad minim</p>
                            </div>
                            <div className="widget-social">
                                <ul>
                                    <li><a href="#!"><i className="ion-social-twitter"></i></a></li>
                                    <li><a href="#!"><i className="ion-social-tumblr"></i></a></li>
                                    <li><a href="#!"><i className="ion-social-facebook"></i></a></li>
                                    <li><a href="#!"><i className="ion-social-instagram-outline"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="copyright">
            <div className="container">
                <div className="copyright-text">
                    <p>&copy; 2023 <span> Furbar </span> Made with  by <a href="#!" target="_blank">Commerce Forz</a></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
