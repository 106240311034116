import React from 'react'
import Header from './Header'
import HeaderMobile from './HeaderMobile'
import OffCanvas from './OffCanvas'
import DetailBanner from './DetailBanner'
import DetailsInfo from './DetailsInfo'
import Footer from './Footer'
import MoveTop from './MoveTop'


function ProductDetails() {
  return (
    <React.Fragment>
        <Header/>
        {/* <HeaderMobile/>
        <OffCanvas/> */}
        <div className="menu-overlay"></div> 
        <DetailBanner/>
        <DetailsInfo/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default ProductDetails
