import React from 'react'
import Header from './Header'
import HeaderMobile from './HeaderMobile'
import OffCanvas from './OffCanvas'
import Footer from './Footer'
import MoveTop from './MoveTop'
import AboutBanner from './AboutBanner'
import AboutInfo from './AboutInfo'

function AboutPage() {
  return (
    <React.Fragment>
        <Header/>
        {/* <HeaderMobile/>
        <OffCanvas/> */}
        <div className="menu-overlay"></div> 
        <AboutBanner/>
        <AboutInfo/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default AboutPage
