import React from 'react'
import Header from './Header'
import HeaderMobile from './HeaderMobile'
import OffCanvas from './OffCanvas'
import Footer from './Footer'
import MoveTop from './MoveTop'
import SignupBanner from './SignupBanner'
import SignupInfo from './SignupInfo'

function SignupPage() {
  return (
    <React.Fragment>
        <Header/>
        {/* <HeaderMobile/>
        <OffCanvas/> */}
        <div className="menu-overlay"></div> 
        <SignupBanner/>
        <SignupInfo/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default SignupPage
