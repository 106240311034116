import React from 'react'
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/store';

import Shop from './Components/Shop';
import Home from './Components/Home';
import Country from './Components/Country';
import ProductDetails from './Components/ProductDetails';
import CartPage from './Components/CartPage';
import CheckoutPage from './Components/CheckoutPage';
import SigninPage from './Components/SigninPage';
import SignupPage from './Components/SignupPage';
import SuccessPage from './Components/SuccessPage';
import FailurePage from './Components/FailurePage';
import AboutPage from './Components/AboutPage';
import ContactPage from './Components/ContactPage';


function App() {
  return (
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route index element={<Country />} />
                <Route path='/home' element={<Home/>} />
                <Route path='/shop' element={<Shop/>} />
                <Route path='/product_details' element={<ProductDetails/>} />
                <Route path='/cart' element={<CartPage/>}/>
                <Route path='/checkout' element={<CheckoutPage/>} />
                <Route path='/signin' element={<SigninPage/>} />
                <Route path='/signup' element={<SignupPage/>}/>
                <Route path='/success' element={<SuccessPage/>}/>
                <Route path='/failure' element={<FailurePage/>}/>
                <Route path='/about' element={<AboutPage/>}/>
                <Route path='/contact' element={<ContactPage/>}/>
            </Routes>
        </BrowserRouter>
    </Provider>
  );
}

export default App;
