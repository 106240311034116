import React from 'react'
import banner1 from '../Components/images/banner/banner-01.jpg'
import banner2 from '../Components/images/banner/banner-02.jpg'
import banner3 from '../Components/images/banner/banner-03.jpg'

function Banner() {
  return (
    <div className="section section-padding-02 mt-n6">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="single-banner">
                        <img src={banner1} alt="Banner"/>

                        <div className="banner-content">
                            <h3 className="title"><a href="shop-grid-4-column.html">New Ruben Teak <br/> Sideboard</a></h3>
                            <span className="discount">30% Off</span>
                            <a className="btn btn-primary btn-hover-dark" href="shop-grid-4-column.html">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="single-banner">
                        <img src={banner2} alt="Banner"/>

                        <div className="banner-content">
                            <h3 className="title"><a href="shop-grid-4-column.html">New Reversible <br/> Sofa</a></h3>
                            <span className="discount">30% Off</span>
                            <a className="btn btn-primary btn-hover-dark" href="shop-grid-4-column.html">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="single-banner">
                        <img src={banner3} alt="Banner"/>

                        <div className="banner-content">
                            <h3 className="title"><a href="shop-grid-4-column.html">New Lamp Light <br/> Collection</a></h3>
                            <span className="discount">30% Off</span>
                            <a className="btn btn-primary btn-hover-dark" href="shop-grid-4-column.html">Shop Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Banner
