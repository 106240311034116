import React from 'react'

function MoveTop() {
  return (
    <a href="#!" className="back-to-top">
        <i className="pe-7s-angle-up"></i>
    </a>
  )
}

export default MoveTop
