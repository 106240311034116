import React,{useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import configData from "../Config.json";
import axios from 'axios';
import cookies from "js-cookies";
import { Link } from 'react-router-dom';

const CheckoutInfo = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [content, setContent] = useState([]);
    const [payment, setPayment] =useState([]);
    const [shipping, setShipping] = useState([]);
    const [shippingFlag, setShippingFlag] = useState(false);
    const [shippingAddressFlag, setShippingAddressFlag] = useState(false);
    const [billingAddressFlag, setBillingAddressFlag] = useState(false);
    const [flag, setFlag] = useState(true);
    const [shipment, setShipment] = useState(false);
    const [paymentMethodID, setPaymentMethodID] = useState('');
    const [shippingMethodID, setShippingMethodID] = useState('');
    const [couponFlag, setCouponFlag] = useState(false);
    const [couponCode, setCouponCode] = useState('');

    const [billFname, setBillFname] = useState('');
    const [billLname, setBillLname] = useState('');
    const [billCountry, setBillCountry] = useState('');
    const [billState, setBillState] = useState('');
    const [billStateOptions, setBillStateOptions] = useState([]);
    const [billAddressLine1, setBillAddressLine1] = useState('');
    const [billAddressLine2, setBillAddressLine2] = useState('');
    const [billTown, setBillTown] = useState('');
    const [billZip, setBillZip] = useState('');
    const [billPhone, setBillPhone] = useState('');
    const [billEmail, setBillEmail] = useState('');

    const [shipFname, setShipFname] = useState('');
    const [shipLname, setShipLname] = useState('');
    const [shipCountry, setShipCountry] = useState('');
    const [shipState, setShipState] = useState('');
    const [shipStateOptions, setShipStateOptions] = useState([]);
    const [shipAddressLine1, setShipAddressLine1] = useState('');
    const [shipAddressLine2, setShipAddressLine2] = useState('');
    const [shipTown, setShipTown] = useState('');
    const [shipZip, setShipZip] = useState('');
    const [shipPhone, setShipPhone] = useState('');
    const [shipEmail, setShipEmail] = useState('');

    useEffect(() => {
        if(flag)
        {
            const fetchData = async () => {
                
                try
                {
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };

                    const response = await axios.get(configData.CommerceURL+'api/orders/'+cookies.getItem("order")+'?include=line_items&fields[orders]=number,skus_count,formatted_payment_method_amount,formatted_subtotal_amount,formatted_discount_amount,formatted_shipping_amount,formatted_total_tax_amount,formatted_gift_card_amount,formatted_total_amount_with_taxes,line_items,metadata&fields[line_items]=item_type,image_url,name,sku_code,formatted_unit_amount,quantity,formatted_total_amount',config);
                    console.log("data start in first use effect");
                    console.log(response.data);
                    console.log("data end in first use effect");

                    setData(response.data.data.attributes)
                    setContent(response.data.included)

                    var metaflag = response.data.data.attributes.metadata.shipping?response.data.data.attributes.metadata.shipping:false;
                    var shippingId = response.data.data.attributes.metadata.shipping_id?response.data.data.attributes.metadata.shipping_id:false;
                    var paymentId = response.data.data.attributes.metadata.payment_id?response.data.data.attributes.metadata.payment_id:false;
                    setShippingFlag(metaflag)
                    setPaymentMethodID(paymentId)
                    setShippingMethodID(shippingId)
                    console.log(response.data.data.attributes.metadata.shipping);
                    console.log('shipping flag '+metaflag)
                }
                catch(e)
                {
                    console.log("error in order axios");
                }
            };
            
            fetchData();

            
            const paymentmethods= async()=>{
            
                try
                {
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    
                    const response = await axios.get(configData.CommerceURL+'api/orders/'+cookies.getItem("order")+'?include=available_payment_methods',config)
                    setPayment([]);
                    response.data.included.map((itm,key)=>{
            
                        const value= {id: itm.id, name: itm.attributes.name, price: itm.attributes.formatted_price_amount};
                        setPayment((prevValues) => [...prevValues, value]);
                    });
                }
                catch(e)
                {
                    console.log("error in payment axios");
                }
            };
            paymentmethods();

            setFlag(false);
        }
        
    },[flag])

    useEffect(()=>{
        
        if(shippingFlag)
        {
            const shippingmethods= async()=>{
                try{
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };

                    const response = await axios.get(configData.CommerceURL+'api/orders/'+cookies.getItem("order")+'/shipments?include=available_shipping_methods',config);
                    setShipping([]);
                    console.log("start shipping");
                    console.log(response.data.data);
                    console.log("end shipping");

                    response.data.included.map( async(itm,key)=>{
                        let id=itm.id;
                        let name=itm.attributes.name;
                        let price=itm.attributes.formatted_price_amount_for_shipment

                        const value= {shippingid: response.data.data[0].id, id: id, name: name, price: price};
                        setShipping((prevValues) => [...prevValues, value]);
        
                    });
                }
                catch(e)
                {
                    console.log("error in axios");
                }

            };
            shippingmethods();
        }
        
    },[shippingFlag])

    useEffect(()=>{
        
        if(billingAddressFlag)
        {
            const addBillingAddress= async()=>{
                try{
                    const data={
                        "data": {
                          "type": "addresses",
                          "attributes": {
                            "first_name": billFname,
                            "last_name": billLname,
                            "line_1": billAddressLine1,
                            "line_2": billAddressLine2,
                            "city": billTown,
                            "zip_code": billZip,
                            "state_code": billState,
                            "country_code": billCountry,
                            "phone": billPhone,
                            "email":billEmail,
                            "billing_info":"Customer Address"
                          }
                        }
                      };
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };

                    const response = await axios.post(configData.CommerceURL+'api/addresses',data,config)
                    
                    const address_data={
                        "data": {
                          "type": "orders",
                          "id": cookies.getItem("order"),
                          "relationships": {
                            "billing_address": {
                              "data": {
                                "type": "addresses",
                                "id": response.data.data.id
                              }
                            }
                            }
                        }
                    };
                    const address_config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };

                    const responses = await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),address_data,address_config)

                    if(responses.status===200)
                    {
                        console.log("Billing Address Saved Successfully");
                    }
                    else{
                        console.log("Error in Saving Billing Address. Please Try Again Later");
                    }  
                    
                    if(!shipment)
                    {
                        const address_data={
                            "data": {
                              "type": "orders",
                              "id": cookies.getItem("order"),
                              "relationships": {
                                "shipping_address": {
                                  "data": {
                                    "type": "addresses",
                                    "id": response.data.data.id
                                  }
                                }
                              },
                              "attributes":{
                                  "metadata":{
                                      "shipping":true,
                                      "shipping_id":'',
                                      "payment_id":''
                                  }
                              }
                            }
                        };
                        const address_config = {
                            headers:{
                                Accept: "application/vnd.api+json",
                                'Content-Type': "application/vnd.api+json",
                                Authorization: "Bearer "+cookies.getItem("Token")
                            }
                        };
                        const responses= await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),address_data,address_config)
                        if(responses.status===200)
                        {
                            setShippingFlag(true);
                            console.log("Shipping Address Saved Successfully");
                        }
                        else{
                            setShippingFlag(false);
                            console.log("Error in Saving Shipping Address. Please Try Again Later");
                        } 
                    }
                }
                catch(e)
                {
                    console.log("error in axios");
                }

            };
            addBillingAddress();
        }
        
    },[billingAddressFlag])

    useEffect(()=>{
        
        if(shippingAddressFlag)
        {
            const addShippingAddress= async()=>{
                try{
                    const data={
                        "data": {
                          "type": "addresses",
                          "attributes": {
                            "business": false,
                            "first_name": shipFname,
                            "last_name": shipLname,
                            "line_1": shipAddressLine1,
                            "line_2":shipAddressLine2,
                            "city": shipTown,
                            "zip_code": shipZip,
                            "state_code": shipState,
                            "country_code": shipCountry,
                            "phone": shipPhone,
                            "email":shipEmail
                          }
                        }
                      };
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    var response = await axios.post(configData.CommerceURL+'api/addresses',data,config)
            
                    const address_data={
                        "data": {
                            "type": "orders",
                            "id": cookies.getItem("order"),
                            "relationships": {
                            "shipping_address": {
                                "data": {
                                "type": "addresses",
                                "id": response.data.data.id
                                }
                            }
                            },
                            "attributes":{
                                "metadata":{
                                    "shipping":true,
                                    "shipping_id":'',
                                    "payment_id":''
                                }
                            }
                        }
                    };
                    const address_config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    var responses = await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),address_data,address_config)
                    
                    if(responses.status==200)
                    {
                        setShippingFlag(true);
                        console.log("Shipping Address Saved Successfully");
                    }
                    else{
                        setShippingFlag(false);
                        console.log("Error in Saving Shipping Address. Please Try Again Later");
                    }
                }
                catch(e)
                {
                    console.log("error in axios");
                }

            };
            addShippingAddress();
        }
        
    },[shippingAddressFlag])

    useEffect(()=>{
        
        if(shippingMethodID!='')
        {
            const addShippingMethodID= async()=>{
                try{
                    const data={
                        "data": {
                            "type": "orders",
                            "id": cookies.getItem("order"),
                            "attributes":{
                                "metadata":{
                                    "shipping":true,
                                    "shipping_id":shippingMethodID?shippingMethodID:'',
                                    "payment_id":paymentMethodID?paymentMethodID:''
                                }
                            }
                        }
                    };
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    const responses= await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),data,config)
                    if(responses.status===200)
                    {
                        console.log("Shipping Method ID patched Successfully");
                    }
                    else{
                        console.log("Error in Patching Shipping Method ID. Please Try Again Later");
                    } 
                }
                catch(e)
                {
                    console.log("error in axios");
                }

            };
            addShippingMethodID();
        }
        
    },[shippingMethodID])

    useEffect(()=>{
        
        if(paymentMethodID!='')
        {
            const addPaymentMethodID= async()=>{
                try{
                    const data={
                        "data": {
                            "type": "orders",
                            "id": cookies.getItem("order"),
                            "attributes":{
                                "metadata":{
                                    "shipping":true,
                                    "shipping_id":shippingMethodID?shippingMethodID:'',
                                    "payment_id":paymentMethodID?paymentMethodID:''
                                }
                            }
                        }
                    };
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    const responses= await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),data,config)
                    if(responses.status===200)
                    {
                        console.log("Payment Method ID patched Successfully");
                    }
                    else{
                        console.log("Error in Patching payment Method ID. Please Try Again Later");
                    } 
                }
                catch(e)
                {
                    console.log("error in axios");
                }

            };
            addPaymentMethodID();
        }
        
    },[paymentMethodID])

    const changeShippingMethod = async(shippingid,methodid) => {
        const data={
            "data": {
              "type": "shipments",
              "id": shippingid,
              "relationships": {
                "shipping_method": {
                  "data": {
                    "type": "shipping_methods",
                    "id": methodid
                  }
                }
              }
            }
        };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        
        var response= await axios.patch(configData.CommerceURL+'api/shipments/'+shippingid,data,config)

        if(response.status===200)
        {
            console.log('Shipping Method Selected');
            setShippingMethodID(methodid)
            setFlag(true);
        }
        else{
            console.log('Please Try again later!!');
            setFlag(false);
        }

    }

    const changePaymentMethod = async(paymentid) => {
        const data={
            "data": {
              "type": "orders",
              "id": cookies.getItem("order"),
              "relationships": {
                "payment_method": {
                  "data": {
                    "type": "payment_methods",
                    "id": paymentid
                  }
                }
              }
            }
        };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        
        const response = await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),data,config)
        console.log(response.data);
        if(response.status===200)
        {
            console.log('Payment Method Selected');
            setPaymentMethodID(paymentid)
            setFlag(true);
        }
        else
        {
            setFlag(false);
        }
    }

    const paymentObject = async()=>{
        const data={
            "data": {
              "type": "paypal_payments",
              "attributes": {
                "return_url": "http://localhost:3000/success",
                "cancel_url": "http://localhost:3000/failure",
                "note_to_payer": "Thanks for Shopping with us"
              },
              "relationships": {
                "order": {
                  "data": {
                    "type": "orders",
                    "id": cookies.getItem("order")
                  }
                }
              }
            }
        };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        
        const response = await axios.post(configData.CommerceURL+'api/paypal_payments',data,config)

        console.log(response.data);
        console.log(response.data.data.attributes.approval_url);
        window.location=response.data.data.attributes.approval_url;
        //navigate('/success'); 
    }

    const handleShipment = () => {
        setShipment(!shipment);
    };

    const fetchStateOptions = (country) => {
        const stateOptionsMap = {
          usa: [
            { code: 'NY', name: 'New York' },
            { code: 'CA', name: 'California' },

          ],
          uk: [
            { code: 'LDN', name: 'London' },
            { code: 'MCR', name: 'Manchester' },
          ],
          canada: [
            { code: 'ON', name: 'Ontario' },
            { code: 'QC', name: 'Quebec' },
          ],
          IN:[
            {code: 'KL', name: 'Kerala'},
            {code: 'TN', name: 'Tamilnadu'}
          ]
        };
    
        return stateOptionsMap[country] || [];
    };

    const handleBillCountry = (event) =>{
        const country = event.target.value;
        setBillCountry(country);

        const fetchedStateOptions = fetchStateOptions(country);
        setBillStateOptions(fetchedStateOptions); 
    }

    const handleBillState = (event) => {
        const state = event.target.value;
        setBillState(state);
    }

    const handleShipCountry = (event) =>{
        const country = event.target.value;
        setShipCountry(country);

        const fetchedStateOptions = fetchStateOptions(country);
        setShipStateOptions(fetchedStateOptions); 
    }

    const handleShipState = (event) => {
        const state = event.target.value;
        setShipState(state);
    }

    const handleBillFname = (event) =>{
        const fname=event.target.value;
        setBillFname(fname);
    }
    const handleBillLname = (event) =>{
        const lname=event.target.value;
        setBillLname(lname);
    }
    const handleBillAddressLine1 = (event) =>{
        const address1=event.target.value;
        setBillAddressLine1(address1);
    }
    const handleBillAddressLine2 = (event) =>{
        const address2=event.target.value;
        setBillAddressLine2(address2);
    }
    const handleBillTown = (event) =>{
        const town=event.target.value;
        setBillTown(town);
    }
    const handleBillZip = (event) =>{
        const zip=event.target.value;
        setBillZip(zip);
    }
    const handleBillPhone = (event) =>{
        const phone=event.target.value;
        setBillPhone(phone);
    }
    const handleBillEmail = (event) =>{
        const email=event.target.value;
        setBillEmail(email)
    }

    const handleShipFname = (event) =>{
        const fname=event.target.value;
        setShipFname(fname);
    }
    const handleShipLname = (event) =>{
        const lname=event.target.value;
        setShipLname(lname);
    }
    const handleShipAddressLine1 = (event) =>{
        const address1=event.target.value;
        setShipAddressLine1(address1);
    }
    const handleShipAddressLine2 = (event) =>{
        const address2=event.target.value;
        setShipAddressLine2(address2);
    }
    const handleShipTown = (event) =>{
        const town=event.target.value;
        setShipTown(town);
    }
    const handleShipZip = (event) =>{
        const zip=event.target.value;
        setShipZip(zip);
    }
    const handleShipPhone = (event) =>{
        const phone=event.target.value;
        setShipPhone(phone);
    }
    const handleShipEmail = (event) =>{
        const email=event.target.value;
        setShipEmail(email)
    }

    const saveAddress = async() =>{
        const data={
            "data": {
              "type": "orders",
              "id": cookies.getItem("order"),
              "attributes": {
                "customer_email": billEmail
              }
            }
        };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        var response = await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),data,config)
        console.log(response.data);
        if(response.status===200)
        {
            console.log('email id added to order '+shipment);
            setBillingAddressFlag(true);
            if(shipment)
            {
                setShippingAddressFlag(true);
            }
            else
            {
                setShippingAddressFlag(false);
            }
                
            const secure = window.location.protocol === 'https'
            cookies.setItem("customer", billEmail, undefined, "/", undefined, secure)

        }
    }

    const toggleCoupon = () =>{
        setCouponFlag(!couponFlag);
    }

    const handleCouponCode = (event) =>{
        const coupon = event.target.value;
        setCouponCode(coupon);
    }   
    
    const submitCoupon = async() =>{
        const data={
            "data": {
              "type": "orders",
              "id": cookies.getItem("order"),
              "attributes": {
                "gift_card_or_coupon_code": couponCode
              }
            }
          };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        var response = await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),data,config)
        
        if(response.status == 200)
        {
            console.log("coupon added successfull");
            setFlag(true);
        }
        else if(response.status == 422)
        {
            console.log("coupon added doesnt exists");
            setFlag(false);
        }
        else{
            console.log("coupon added failed");
            setFlag(false);
        }
        
    }



  return (
    <div className="section section-padding">
        <div className="container">

            <div className="checkout-info mt-30">
                <p className="info-header error"><i className="fa fa-exclamation-circle"></i> <strong>Error:</strong> Username is required.</p>
            </div>

            <div className="checkout-info mt-30">
                <p className="info-header"> <i className="fa fa-exclamation-circle"></i> Returning customer? <a data-bs-toggle="collapse" href="#login">Click here to login</a></p>

                <div className="collapse" id="login">
                    <div className="card-body">
                        <p>If you have shopped with us before, please enter your details in the boxes below. If you are a new customer, please proceed to the Billing & Shipping section.</p>
                        <form action="#">

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="single-form">
                                        <input type="email" placeholder="Username or email *"/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="single-form">
                                        <input type="password" placeholder="Password"/>
                                    </div>
                                </div>
                            </div>

                            <div className="single-form d-flex align-items-center">
                                <button className="btn btn-primary btn-hover-dark">Login</button>
                                <div className="form-checkbox">
                                    <input type="checkbox" id="remember"/>
                                    <label htmlFor="remember"><span></span> Remember Me</label>
                                </div>
                            </div>
                            <div className="forget">
                                <a href="#">Lost Your Password</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="checkout-info mt-30">
                <p className="info-header"> <i className="fa fa-exclamation-circle"></i> Have a coupon? <a onClick={toggleCoupon}>Click here to enter your code</a></p>

                {couponFlag && (
                <div id="coupon" style={{display: 'show'}}>
                    <div className="card-body">
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="single-form">
                                        <input type="text" placeholder="Coupon code" value={couponCode} onChange={handleCouponCode}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="single-form">
                                        <button className="btn btn-primary btn-hover-dark" onClick={submitCoupon}>Coupon</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                )}
            </div>

            <form action="#">
                <div className="row">
                    <div className="col-lg-7">

                        <div className="checkout-form">
                            <div className="checkout-title">
                                <h4 className="title">Billing details</h4>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="single-form">
                                        <input type="text" placeholder="First name *" value={billFname} onChange={handleBillFname}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="single-form">
                                        <input type="text" placeholder="Last name *" value={billLname} onChange={handleBillLname}/>
                                    </div>
                                </div>
                                
                                <div className="col-sm-12">
                                    <div className="single-select2">
                                        <div className="form-select2">
                                        <select className="select2" value={billCountry} onChange={handleBillCountry} style={{height: '40px',borderColor: '#d7d7d7',color: '#6c757d',paddingLeft: '10px',fontSize: '14px'}}>
                                            <option value="0">Select a Country *</option>
                                            <option value="USA">USA</option>
                                            <option value="UK">UK</option>
                                            <option value="CANADA">Canada</option>
                                            <option value="IN">India</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="single-form">
                                        <label className="form-label">Street address *</label>
                                        <input type="text" placeholder="House number and street name" value={billAddressLine1} onChange={handleBillAddressLine1}/>
                                        <input type="text" placeholder="Apartment, suite, unit etc. (optional)" value={billAddressLine2} onChange={handleBillAddressLine2}/>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="single-form">
                                        <input type="text" placeholder="Town / City *" value={billTown} onChange={handleBillTown}/>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="single-select2">
                                        <div className="form-select2">
                                        <select className="select2" value={billState} onChange={handleBillState} style={{height: '40px',borderColor: '#d7d7d7',color: '#6c757d',paddingLeft: '10px',fontSize: '14px'}}>
                                            <option value="">Select a state</option>
                                            {billStateOptions.map((state) => (
                                            <option key={state.code} value={state.code}>
                                                {state.name}
                                            </option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="single-form">
                                        <input type="text" placeholder="Postcode / ZIP" value={billZip} onChange={handleBillZip}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="single-form">
                                        <input type="text" placeholder="Phone *" value={billPhone} onChange={handleBillPhone}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="single-form">
                                        <input type="text" placeholder="Email address *" value={billEmail} onChange={handleBillEmail}/>
                                    </div>
                                </div>
                            </div>

                            <div className="single-form checkbox-checkbox">
                                <input type="checkbox" id="account"/>
                                <label htmlFor="account"> <span></span> Create an account?</label>
                            </div>

                            <div className="checkout-account">
                                <div className="single-form">
                                    <input type="password" placeholder="Create account Password *" className="form-control"/>
                                </div>
                            </div>

                            <div className="single-form checkbox-checkbox">
                                <input type="checkbox" id="shipping" checked={shipment} onChange={handleShipment} />
                                <label htmlFor="shipping"> <span></span> Ship to a different address?</label>
                            </div>

                            {shipment && (
                            <div className="checkout-shipping" style={{display: 'block'}}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="single-form">
                                            <input type="text" placeholder="First name *" value={shipFname} onChange={handleShipFname}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="single-form">
                                            <input type="text" placeholder="Last name *" value={shipLname} onChange={handleShipLname}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="single-select2">
                                            <div className="form-select2">
                                            <select className="select2" value={shipCountry} onChange={handleShipCountry} style={{height: '40px',borderColor: '#d7d7d7',color: '#6c757d',paddingLeft: '10px',fontSize: '14px'}}>
                                                <option value="0">Select a Country *</option>
                                                <option value="usa">USA</option>
                                                <option value="uk">UK</option>
                                                <option value="canada">Canada</option>
                                                <option value="IN">India</option>
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="single-form">
                                            <label className="form-label">Street address *</label>
                                            <input type="text" placeholder="House number and street name" value={shipAddressLine1} onChange={handleShipAddressLine1}/>
                                            <input type="text" placeholder="Apartment, suite, unit etc. (optional)" value={shipAddressLine2} onChange={handleShipAddressLine2}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="single-form">
                                            <input type="text" placeholder="Town / City *" value={shipTown} onChange={handleShipTown}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="single-select2">
                                            <div className="form-select2">
                                            <select className="select2" value={shipState} onChange={handleShipState} style={{height: '40px',borderColor: '#d7d7d7',color: '#6c757d',paddingLeft: '10px',fontSize: '14px'}}>
                                                <option value="">Select a state</option>
                                                {shipStateOptions.map((state) => (
                                                <option key={state.code} value={state.code}>
                                                    {state.name}
                                                </option>
                                                ))}
                                            </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="single-form">
                                            <input type="text" placeholder="Postcode / ZIP" value={shipZip} onChange={handleShipZip}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="single-form">
                                            <input type="text" placeholder="Phone *" value={shipPhone} onChange={handleShipPhone}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="single-form">
                                            <input type="text" placeholder="Email address *" value={shipEmail} onChange={handleShipEmail}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}

                            <div className="single-form checkout-note">
                                <label className="form-label">Order notes</label>
                                <textarea placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                            </div>
                            <div className="single-form">
                                <button className="btn btn-dark btn-hover-primary" onClick={()=>saveAddress()}>Save Address</button>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-5">
                        <div className="checkout-order">
                            <div className="checkout-title">
                                <h4 className="title">Your Order</h4>
                            </div>

                            <div className="checkout-order-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="Product-name">Product</th>
                                            <th className="Product-price">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {content.map((item,key)=>{
                                        if(item.attributes.sku_code!==null)
                                        {
                                            return (
                                                <tr key={key}>
                                                    <td className="Product-name">
                                                        <p>{item.attributes.name.slice(0, 35)} × {item.attributes.quantity}</p>
                                                    </td>
                                                    <td className="Product-price">
                                                        <p>{item.attributes.formatted_total_amount}</p>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}    
                                        
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="Product-name">
                                                <p>Subtotal</p>
                                            </td>
                                            <td className="Product-price">
                                                <p>{data['formatted_subtotal_amount']}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="Product-name">
                                                <p>Discount Amount</p>
                                            </td>
                                            <td className="Product-price">
                                                <p>{data['formatted_discount_amount']}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="Product-name">
                                                <p>Gift Card Amount</p>
                                            </td>
                                            <td className="Product-price">
                                                <p>{data['formatted_gift_card_amount']}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="Product-name">
                                                <p>Payment Amount</p>
                                            </td>
                                            <td className="Product-price">
                                                <p>{data['formatted_payment_method_amount']}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="Product-name">
                                                <p>Total Tax Amount</p>
                                            </td>
                                            <td className="Product-price">
                                                <p>{data['formatted_total_tax_amount']}</p>
                                            </td>
                                        </tr>
                                        
                                        <tr>
                                        <td>
                                            <p className="value">Shipping</p>
                                        </td>
                                        <td>
                                            <ul className="shipping-list">
                                            {shipping.map((item,key) => {
                                                return(
                                                <li className="radio" key={key}>
                                                    <input type="radio" name="shipping" id={item.id} checked={item.id===shippingMethodID?true:false} value={item.id} onClick={()=>changeShippingMethod(item.shippingid,item.id)} />
                                                    <label htmlFor={item.id}><span></span> {item.name}&nbsp;&nbsp;{item.price}</label>
                                                </li>
                                                )}
                                            )}
                                                
                                            </ul>
                                        </td>
                                    </tr>
                                        <tr>
                                            <td className="Product-name">
                                                <p>Total</p>
                                            </td>
                                            <td className="total-price">
                                                <p>{data['formatted_total_amount_with_taxes']}</p>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div className="checkout-payment">
                                <ul>
                                {payment.map((item,key) => {


                                return(
                                    <li key={key}>
                                        <div className="single-payment">
                                            <div className="payment-radio radio">
                                                <input type="radio" name="radio" checked={item.id===paymentMethodID?true:false} id={item.id} value={item.id} onClick={()=>changePaymentMethod(item.id)}/>
                                                <label htmlFor={item.id}><span></span> {item.name}&nbsp;&nbsp;&nbsp;{item.price} </label>

                                                <div className="payment-details">
                                                    <p>Please send a Check to Store name with Store Street, Store Town, Store State, Store Postcode, Store Country.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                                })}    
                                    
                                </ul>

                                <div className="single-form">
                                    <button className="btn btn-primary btn-hover-dark d-block" type='button' onClick={()=>paymentObject()}>Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
  )
}

export default CheckoutInfo
