import React from 'react'
import image from '../Components/images/product/product-01.jpg'
import banner from '../Components/images/banner/banner-04.jpg'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination} from 'swiper';
import 'swiper/swiper.css'
import '../Components/style.css'

function NewProducts() {
  return (
    <div className="section section-padding mt-n10">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6">
                    <div className="single-banner-02 mt-10">
                        <img src={banner} alt="Banner"/>

                        <div className="banner-content">
                            <h3 className="title"><a href="shop-grid-left-sidebar.html">All New Furniture</a></h3>
                            <span className="discount">40% Off</span>
                            <a className="btn btn-primary btn-hover-dark" href="shop-grid-left-sidebar.html">Shop Now</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 ">
                    <div className="product-wrapper mt-9 product-active">

                        <div className="product-top-wrapper">

                            <div className="section-title">
                                <h2 className="title"># New Products</h2>
                            </div>

                            <div className="product-menu">
                                <ul className="nav">
                                    <li><button className="active" data-bs-toggle="tab" data-bs-target="#tab1">Just Now</button></li>
                                    <li><button data-bs-toggle="tab" data-bs-target="#tab2">New Arrival</button></li>
                                    <li><button data-bs-toggle="tab" data-bs-target="#tab3">Top Seals</button></li>
                                </ul>
                            </div>

                            <div className="swiper-arrows">
                                <div className="swiper-button-prev newproducts"><i className="pe-7s-angle-left"></i></div>
                                <div className="swiper-button-next newproducts"><i className="pe-7s-angle-right"></i></div>
                            </div>
                        </div>

                        <div className="product-tabs-content">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tab1">
                                    <div className="swiper-container">
                                    <Swiper modules={[Navigation, Pagination]}
                                        spaceBetween={20}
                                        slidesPerView={3}
                                        navigation={{
                                                nextEl: '.swiper-button-next.newproducts',
                                                prevEl: '.swiper-button-prev.newproducts',
                                            }}
                                        pagination={{ clickable: true }}
                                        scrollbar={{ draggable: true }}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        onSlideChange={() => console.log('slide change')} className="swiper-wrapper">
                                            <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-product">
                                                    <a href="product-details.html"><img src={image} alt="product"/></a>
                                                    <div className="product-content">
                                                        <h4 className="title"><a href="product-details.html">Elona bedside grey table</a></h4>
                                                        <div className="price">
                                                            <span className="sale-price">$40.00</span>
                                                        </div>
                                                    </div>
                                                    <ul className="product-meta">
                                                        <li><a className="action" data-bs-toggle="modal" data-bs-target="#quickView" href="#!;"><i className="pe-7s-search"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-shopbag"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-like"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-product">
                                                    <a href="product-details.html"><img src={image} alt="product"/></a>
                                                    <div className="product-content">
                                                        <h4 className="title"><a href="product-details.html">Elona bedside grey table</a></h4>
                                                        <div className="price">
                                                            <span className="sale-price">$40.00</span>
                                                        </div>
                                                    </div>
                                                    <ul className="product-meta">
                                                        <li><a className="action" data-bs-toggle="modal" data-bs-target="#quickView" href="#!;"><i className="pe-7s-search"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-shopbag"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-like"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-product">
                                                    <a href="product-details.html"><img src={image} alt="product"/></a>
                                                    <div className="product-content">
                                                        <h4 className="title"><a href="product-details.html">Elona bedside grey table</a></h4>
                                                        <div className="price">
                                                            <span className="sale-price">$40.00</span>
                                                        </div>
                                                    </div>
                                                    <ul className="product-meta">
                                                        <li><a className="action" data-bs-toggle="modal" data-bs-target="#quickView" href="#!;"><i className="pe-7s-search"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-shopbag"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-like"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="single-product">
                                                    <a href="product-details.html"><img src={image} alt="product"/></a>
                                                    <div className="product-content">
                                                        <h4 className="title"><a href="product-details.html">Elona bedside grey table</a></h4>
                                                        <div className="price">
                                                            <span className="sale-price">$40.00</span>
                                                        </div>
                                                    </div>
                                                    <ul className="product-meta">
                                                        <li><a className="action" data-bs-toggle="modal" data-bs-target="#quickView" href="#!;"><i className="pe-7s-search"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-shopbag"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-like"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            </SwiperSlide>
                                    </Swiper>        
                                            
                                        
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab2">
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper">

                                            <div className="swiper-slide">
                                                <div className="single-product">
                                                    <a href="product-details.html"><img src="assets/images/product/product-04.jpg" alt="product"/></a>
                                                    <div className="product-content">
                                                        <h4 className="title"><a href="product-details.html">High quality vase bottle</a></h4>
                                                        <div className="price">
                                                            <span className="sale-price">$240.00</span>
                                                        </div>
                                                    </div>
                                                    <ul className="product-meta">
                                                        <li><a className="action" data-bs-toggle="modal" data-bs-target="#quickView" href="#!;"><i className="pe-7s-search"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-shopbag"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-like"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                           
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab3">
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper">

                                            <div className="swiper-slide">
                                                <div className="single-product">
                                                    <a href="product-details.html"><img src="assets/images/product/product-07.jpg" alt="product"/></a>
                                                    <div className="product-content">
                                                        <h4 className="title"><a href="product-details.html">Wooden decorations</a></h4>
                                                        <div className="price">
                                                            <span className="sale-price">$240.00</span>
                                                        </div>
                                                    </div>
                                                    <ul className="product-meta">
                                                        <li><a className="action" data-bs-toggle="modal" data-bs-target="#quickView" href="#!;"><i className="pe-7s-search"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-shopbag"></i></a></li>
                                                        <li><a className="action" href="#!;"><i className="pe-7s-like"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                           
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewProducts
