import React from 'react'
import Header from './Header'
import HeaderMobile from './HeaderMobile'
import OffCanvas from './OffCanvas'
import Footer from './Footer'
import MoveTop from './MoveTop'
import SigninBanner from './SigninBanner'
import SigninInfo from './SigninInfo'

function SigninPage() {
  return (
    <React.Fragment>
        <Header/>
        {/* <HeaderMobile/>
        <OffCanvas/> */}
        <div className="menu-overlay"></div> 
        <SigninBanner/>
        <SigninInfo/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default SigninPage
