import React,{useEffect, useState} from 'react'
import configData from "../Config.json";
import axios from 'axios';
import cookies from "js-cookies";
import { Link } from 'react-router-dom';
import cart from '../Components/images/cart.png'

const SuccessInfo = () => {

    useEffect(()=>{

        const fetchData = async () => {
            console.log("success page start ");   
            try
            {
                const data={
                    "data": {
                      "type": "orders",
                      "id": cookies.getItem("order"),
                      "attributes": {
                        "_place": true
                      }
                    }
                };
                const config = {
                    headers:{
                        Accept: "application/vnd.api+json",
                        'Content-Type': "application/vnd.api+json",
                        Authorization: "Bearer "+cookies.getItem("Token")
                    }
                };
                
                const response = await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),data,config)
               
                console.log(response.data);
                cookies.removeItem('order');
            }
            catch(e)
            {
                console.log("error in order axios");
            }
        };
        
        fetchData();
    },[])


  return (
    <div className="section section-padding" style={{marginTop:'70px'}}>
        <div className="container">
            <div className="cart-wrapper">
                <div className="empty-cart text-center">
                    <h2 className="empty-cart-title">Payment Successfull! The order is on the way</h2>
                    <div className="empty-cart-img">
                        <img src={cart} alt="Cart"/>
                    </div>
                    <br/><br/><br/>
                    <Link to='/home' className="btn btn-primary btn-hover-dark"><i className="fa fa-angle-left"></i> Continue browsing</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SuccessInfo
