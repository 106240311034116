import React from 'react'
import Header from './Header'
import HeaderMobile from './HeaderMobile'
import OffCanvas from './OffCanvas'
import Footer from './Footer'
import MoveTop from './MoveTop'
import ContactBanner from './ContactBanner'
import ContactInfo from './ContactInfo'

function ContactPage() {
  return (
    <React.Fragment>
        <Header/>
        {/* <HeaderMobile/>
        <OffCanvas/> */}
        <div className="menu-overlay"></div> 
        <ContactBanner/>
        <ContactInfo/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default ContactPage
