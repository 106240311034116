import React from 'react'
import slider from '../Components/images/bg-1.jpg'

function SubBanner() {
  return (
    <div className="section call-to-action" style={{backgroundImage:`url(${slider})`}}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">

                    <div className="call-to-action-content text-center">
                        <h1 className="title">Welcome To Store</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a href="shop-grid-left-sidebar.html" className="btn btn-primary btn-hover-dark">purchase now</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default SubBanner
