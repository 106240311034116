import React,{useEffect, useState} from 'react'
import configData from "../Config.json";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import cookies from "js-cookies";

const Products=()=> {

    const [data, setData] = useState([]);
    const [price, setPrice] = useState([]);
    const [meta, setMeta] = useState([]);
    const [nav, setNav] = useState('grid');

    const category=[];

    category['Backpack']=['BACKPACK000000FFFFFFXXXX','BACKPACK818488000000XXXX','BACKPACKFFFFFF000000XXXX'];
    category['Cap']=['5PANECAP000000FFFFFFXXXX','5PANECAP9D9CA1FFFFFFXXXX','BASEBHAT000000FFFFFFXXXX','BASEBHATFFFFFF000000XXXX','MESHBACK323230FFFFFFXXXX'];
    category['Apron']=['APRONXXX000000FFFFFFXXXX','APRONXXXFFFFFF000000XXXX'];
    category['Bean']=['BEACHAIR000000FFFFFFXXXX','BEACHAIRFFFFFF000000XXXX'];
    category['Beanie']=['BEANIEXX000000FFFFFFXXXX','BEANIEXXFFFFFF000000XXXX','CROPTOPWFFFFFF000000SXXX','CUFFBEANA6A5A0000000XXXX','CUFFBEANFFFFFF000000XXXX','PPOMBEAN000000FFFFFFXXXX','PPOMBEAN74736EFFFFFFXXXX'];
    category['Baby']=['BABYBIBXA19D9D000000XXXX','BEANIEXXFFFFFF000000XXXX','BODYBSSS000000FFFFFF6MXX','BODYBSSSFFFFFF00000012MX','BODYBSSSFFFFFF0000006MXX'];
    category['Bottle']=['BODYBSSSFFFFFF0000006MXX'];
    category['Towel']=['BTOW3060FFFFFF000000XXXX','BTOW3060000000FFFFFFXXXX'];
    category['Top']=['CROPTOPW000000FFFFFFSXXX','CROPTOPW000000FFFFFFXSXX','CROPTOPWE8E8E8000000LXXX','CROPTOPWE8E8E8000000LXXX','CROPTOPW000000FFFFFFLXXX','CROPTOPW000000FFFFFFMXXX','CROPTOPWFFFFFF000000XSXX','CROPTOPWE8E8E8000000SXXX','CROPTOPWE8E8E8000000XSXX','CROPTOPWFFFFFF000000LXXX','CROPTOPWFFFFFF000000MXXX','CROPTOPWFFFFFF000000SXXX'];
    category['Bag']=['BEACHBAG000000FFFFFFXXXX','BEACHBAGFFFFFF000000XXXX','DRAWSBAGFFFFFF000000XXXX','DUFFLBAG000000FFFFFFXXXX','DUFFLBAG000000FFFFFFXXXX','DRAWSBAG000000FFFFFFXXXX','SOCKSXXXFFFFFF000000SXXX','TOTEBAGLE7DDC7000000XXXX','TOTEBAGX000000FFFFFFXXXX','TOTEBAGXE7DDC7000000XXXX'];
    category['Mug']=['EMUG12OZFFFFFF000000XXXX','GMUG15OZFFFFFF000000XXXX'];
    category['Mask']=['FACEMASK000000FFFFFFXXXX','FACEMASKFFFFFF000000XXXX'];
    category['Fanny']=['FANNPACK000000FFFFFFXXXX','FANNPACKFFFFFF000000XXXX'];
    category['Flops']=['FLIPFLOP000000FFFFFF75XX','FLIPFLOPFFFFFF00000075XX','FLIPFLOP000000FFFFFF7XXX','FLIPFLOP000000FFFFFF85XX','FLIPFLOP000000FFFFFF8XXX','FLIPFLOP000000FFFFFF9XXX','FLIPFLOPFFFFFF0000007XXX','FLIPFLOPFFFFFF00000085XX','FLIPFLOPFFFFFF00000085XX','FLIPFLOPFFFFFF0000009XXX'];
    category['Case']=['GLCS21UXXXXXXXFFFFFFXXXX','GLCS21PXXXXXXXFFFFFFXXXX','IPC11PXX000000FFFFFFXXXX','IPC11XXX000000FFFFFFXXXX','IPC12PMXXXXXXX000000XXXX',''];
    category['Hoodie']=['HOODIEMX000000FFFFFFLXXX','HDRESSWX000000FFFFFFLXXX','HDRESSWX000000FFFFFFMXXX','HDRESSWX000000FFFFFFSXXX','HOODIEMX000000FFFFFFSXXX','HOODIEMX000000FFFFFFXLXX','HOODIEMX7F7F7F000000LXXX','HOODIEMX7F7F7F000000LXXX','HOODIEMX000000FFFFFFMXXX','HOODIEMXFFFFFF000000LXXX','HOODIEMXFFFFFF000000MXXX','HOODIEMXFFFFFF000000SXXX','HOODIEMXFFFFFF000000XLXX','HOODIEMX7F7F7F000000SXXX','HOODIEMX7F7F7F000000XLXX','HOODIEUL000000FFFFFFLXXX','HOODIEUL000000FFFFFFMXXX','HOODIEUL000000FFFFFFMXXX','HOODIEUL000000FFFFFFXLXX','HOODIEUL000000FFFFFFXSXX','HOODIEUZ000000FFFFFFLXXX','HOODIEUZ000000FFFFFFMXXX','HOODIEUZ000000FFFFFFSXXX','HOODIEUZ000000FFFFFFXLXX','HOODIEUZ000000FFFFFFXSXX','HOODIEWC000000FFFFFFLXXX','HOODIEWC000000FFFFFFMXXX','HOODIEWC000000FFFFFFSXXX','HOODIEWC000000FFFFFFXSXX','HOODIEWC575C51000000LXXX','HOODIEWC575C51000000MXXX','HOODIEWC575C51000000SXXX','HOODIEWC575C51000000XSXX'];
    category['Sleeve']=['LAPTSL13000000FFFFFFXXXX','LAPTSL15000000FFFFFFXXXX'];
    category['Notebook']=['NOTEBOOK000000FFFFFFXXXX','NOTEBOOKFFFFFF000000XXXX'];
    category['Pillow']=['PILL1818000000FFFFFFXXXX','PILL1818FFFFFF000000XXXX','PILL2012000000FFFFFFXXXX','PILL2012FFFFFF000000XXXX'];
    category['Polo']=['POLOMXXX000000FFFFFFLXXX','POLOMXXX000000FFFFFFLXXX','POLOMXXX000000FFFFFFSXXX','POLOMXXXC2C2C2000000LXXX','POLOMXXXC2C2C2000000MXXX','POLOMXXXC2C2C2000000SXXX','POLOMXXXC2C2C2000000SXXX','POLOMXXXFFFFFF000000MXXX','POLOMXXXFFFFFF000000SXXX','POLOWXXX000000FFFFFFLXXX','POLOWXXX000000FFFFFFMXXX','POLOWXXX000000FFFFFFSXXX','POLOWXXXFFFFFF000000LXXX','POLOWXXXFFFFFF000000MXXX','POLOWXXXFFFFFF000000SXXX'];
    category['Frames']=['POST2130000000000000XXXX','POST2130FFFFFF000000XXXX','POST2130FFFFFF000000XXXX','POST6191FFFFFF000000XXXX'];
    category['Shirt']=['SHIRT34S000000FFFFFFLXXX','SHIRT34S000000FFFFFFMXXX','SHIRT34S000000FFFFFFSXXX','SHIRT34S000000FFFFFFXLXX','SHIRT34S000000FFFFFFXSXX','SHIRT34S000000FFFFFFXSXX','SHIRT34SFFFFFF000000MXXX','SHIRT34SFFFFFF000000SXXX','SHIRT34SFFFFFF000000XLXX','SHIRT34SFFFFFF000000XSXX','SHIRTMLS000000FFFFFFLXXX','SHIRTMLS000000FFFFFFMXXX','SHIRTMLS000000FFFFFFSXXX','SHIRTMLS000000FFFFFFXLXX','SHIRTMLS000000FFFFFFXLXX','SHIRTMLS9B969C000000MXXX','SHIRTMLS9B969C000000SXXX','SHIRTMLS9B969C000000XLXX','SHIRTMLSFFFFFF000000LXXX','SHIRTMLSFFFFFF000000MXXX','SHIRTMLSFFFFFF000000SXXX','SHIRTMLSFFFFFF000000XLXX','SHIRTMMX000000FFFFFFLXXX','SHIRTMMX000000FFFFFFMXXX','SHIRTMMX000000FFFFFFSXXX','SHIRTMMX000000FFFFFFSXXX','SHIRTMMX8E8C8D000000MXXX','SHIRTMMX8E8C8D000000SXXX','SHIRTMMXFFFFFF000000LXXX','SHIRTMMXFFFFFF000000LXXX','SHIRTMMXFFFFFF000000SXXX','SHIRTWLS000000FFFFFFLXXX','SHIRTWLS000000FFFFFFMXXX','SHIRTWLS000000FFFFFFMXXX','SHIRTWLS000000FFFFFFMXXX','SHIRTWLS9397A0000000LXXX','SHIRTWLS9397A0000000MXXX','SHIRTWLS9397A0000000SXXX','SHIRTWLS9397A0000000XSXX','SHIRTWLSFFFFFF000000LXXX','SHIRTWLSFFFFFF000000MXXX','SHIRTWLSFFFFFF000000MXXX','SHIRTWLSFFFFFF000000XSXX'];
    category['Socks']=['SHIRTWLSFFFFFF000000XSXX','SHIRTWLSFFFFFF000000XSXX','SOCKSXXX000000FFFFFFSXXX','SOCKSXXXFFFFFF000000LXXX','SOCKSXXXFFFFFF000000MXXX','SOCKSXXXFFFFFF000000SXXX'];


    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('category');
    const currency = cookies.getItem("currency");

    useEffect(() => {
        
        const fetchData = async () => {
            
            try
            {
                const config = {
                    headers:{
                        Accept: "application/vnd.api+json",
                        Authorization: "Bearer "+cookies.getItem("Token")
                    }
                };
                let filter='';
                if(type!=null){
                    filter = 'filter[q][code_in]='+category[type]+'&';
                }
                else{
                    filter = '';
                }

                const response = await axios.get(configData.CommerceURL+'api/skus?'+filter+'include=prices&page[size]=14',config);
                console.log(response.data);
                console.log(response.data.data)

                setData(response.data.data)
                setPrice(response.data.included)
                setMeta(response.data.meta)
            }
            catch(e)
            {
                console.log("error in axios");
            }
        };
        
        fetchData();
        
    },[type])
    
    useEffect(() => {
        
        const fetchData = async () => {
            if(cookies.getItem("order")==null)
            {
                console.log('order use effect executed');
                try
                {   
                    const body = {
                        data: { 
                            type: "orders",
                            attributes:{
                                metadata:{
                                    shipping:false,
                                    shipping_id:'',
                                    payment_id:''
                                }
                            }
                        },
                        relationship:{
                            "market": cookies.getItem("market")
                        }
                    };
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    const response = await axios.post(configData.CommerceURL+'api/orders',body,config)
                    
                    const secure = window.location.protocol === 'https'
                    cookies.setItem("order", response.data.data.id, undefined, "/", undefined, secure)
                    
                }
                catch(e)
                {
                    console.log("error in axios");
                }
            }
        };
        
        fetchData();
        
    },[cookies.getItem("order")])

    const addCart = async(product_id,name,image)=>{
        const body = {
            "data": {
                "type": "line_items",
                "attributes": {
                "quantity": 1,
                "name": name,
                "image_url": image,
                "_update_quantity": true
                },
                "relationships": {
                "order": {
                    "data": {
                    "type": "orders",
                    "id": cookies.getItem("order")
                    }
                },
                "item": {
                    "data": {
                    "type": "skus",
                    "id": product_id
                    }
                }
                }
            }
        };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        const response = await axios.post(configData.CommerceURL+'api/line_items',body,config)
        if(response.status == 201)
        {  
            console.log("Product Added to Cart Successfully");  
            navigate('/cart');
        }
        else
            console.log("Product Added to Cart Failed!")                                                                                                                                                                                                
    }

    return (
    <div className="section section-padding mt-n10">
        <div className="container">
            <div className="shop-top-bar">
                <div className="shop-text">
                    <p><span>{data.length}</span> Product Found of <span>{meta.record_count}</span></p>
                </div>
                <div className="shop-tabs">
                    <ul className="nav">
                        <li><button className={nav==="grid"?'active':''} data-bs-toggle="tab" onClick={()=>setNav("grid")}><i className="fa fa-th"></i></button></li>
                        <li><button className={nav==="list"?'active':''} data-bs-toggle="tab" onClick={()=>setNav("list")}><i className="fa fa-list"></i></button></li>
                    </ul>
                </div>
                <div className="shop-sort">
                    <span className="title">Sort By :</span>
                    <select className="nice_select">
                        <option value="1">Default</option>
                        <option value="2">Default</option>
                        <option value="3">Default</option>
                        <option value="4">Default</option>
                    </select>
                </div>
            </div>

            <div className="tab-content">
                <div className={nav==='grid'?'tab-pane fade  show active':'tab-pane fade'} id="grid">

                    <div className="shop-product-wrapper">
                        <div className="row">

                            {data.map((item,key) => {

                                let item_price,compare_price;
                                let prc=item.relationships.prices.data;
                                prc.map((itm)=>{
                                    const foundElement = price.find(it =>  it.id === itm.id && it.attributes.currency_code===currency);
                                    if(foundElement!=null)
                                    {
                                        item_price=foundElement.attributes.formatted_amount;
                                        compare_price=foundElement.attributes.formatted_compare_at_amount;
                                    }
                                })
                               return (
                            <div className="col-lg-3 col-sm-6" key={key}>

                                <div className="single-product">
                                    <Link to={`/product_details?sku=${item.id}`}><img src={item.attributes.image_url} alt="product"/></Link>
                                    <div className="product-content">
                                        <h4 className="title"><Link to={`/product_details?sku=${item.id}`}>{item.attributes.name}</Link></h4>
                                        <div className="price">
                                            <span className="sale-price">{item_price}</span>
                                        </div>
                                    </div>
                                    <ul className="product-meta">
                                        <li><a className="action" data-bs-toggle="modal" data-bs-target="#!quickView" href="#!"><i className="pe-7s-search"></i></a></li>
                                        <li><button className="action" onClick={()=>addCart(item.id,item.attributes.name,item.attributes.image_url)}><i className="pe-7s-shopbag"></i></button></li>
                                        <li><a className="action" href="#!"><i className="pe-7s-like"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            )})}

                            
                        </div>
                    </div>

                </div>
                <div className={nav==='list'?'tab-pane fade  show active':'tab-pane fade'} id="list">

                    <div className="shop-product-wrapper">
                        
                    {data.map((item,key) => {

                        let item_price,compare_price;
                        let prc=item.relationships.prices.data;
                        prc.map((itm)=>{
                            const foundElement = price.find(it =>  it.id === itm.id && it.attributes.currency_code===currency);
                            if(foundElement!=null)
                            {
                                item_price=foundElement.attributes.formatted_amount;
                                compare_price=foundElement.attributes.formatted_compare_at_amount;
                            }
                        })
                        return (
                        <div className="single-product-02 product-list" key={key}>
                            <div className="product-images">
                                <Link to={`/product_details?sku=${item.id}`}><img src={item.attributes.image_url} alt="product"/></Link>

                                <ul className="product-meta">
                                    <li><a className="action" data-bs-toggle="modal" data-bs-target="#!quickView" href="#!"><i className="pe-7s-search"></i></a></li>
                                    <li><a className="action" href="#!"><i className="pe-7s-shopbag"></i></a></li>
                                    <li><a className="action" href="#!"><i className="pe-7s-like"></i></a></li>
                                </ul>
                            </div>
                            <div className="product-content">
                                <h4 className="title"><Link to={`/product_details?sku=${item.id}`}>{item.attributes.name}</Link></h4>
                                <div className="price">
                                    <span className="sale-price">{item_price}</span>
                                </div>
                                <p>{item.attributes.description}</p>
                            </div>
                        </div>
                        )})}

                    </div>

                </div>
            </div>

            {/* <div className="page-pagination">
                <ul className="pagination justify-content-center">
                    <li className="page-item"><a className="page-link" href="#!"><i className="fa fa-angle-left"></i></a></li>
                    <li className="page-item"><a className="page-link" href="#!">1</a></li>
                    <li className="page-item"><a className="page-link active" href="#!">2</a></li>
                    <li className="page-item"><a className="page-link" href="#!">3</a></li>
                    <li className="page-item"><a className="page-link" href="#!"><i className="fa fa-angle-right"></i></a></li>
                </ul>
            </div> */}

        </div>
    </div>
    )
}



export default Products;

