import React from 'react'
import { Link } from 'react-router-dom';
import banner from '../Components/images/page-banner.jpg'

function SigninBanner() {
  return (
    <div class="section page-banner-section" style={{ backgroundImage: `url(${banner})`}}>
        <div class="container">

            <div class="page-banner-content">
                <h2 class="title">Login</h2>

                <ul class="breadcrumb">
                    <li><Link to='/home'>Home</Link></li>
                    <li class="active">Login</li>
                </ul>
            </div>

        </div>
    </div>
  )
}

export default SigninBanner
