import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  country: 'USA',
  market: '13095',
  currency:'USD',
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateCountry: (state, action) => {
      state.country = action.payload;
    },
    updateMarket: (state, action) => {
      state.market = action.payload;
    },
    updateCurrency: (state, action) => {
        state.currency = action.payload;
    },
  },
});

export const { updateCountry, updateMarket, updateCurrency } = dataSlice.actions;

export default dataSlice.reducer;
