import React from 'react'

function TextBanner() {
  return (
    <div className="section section-padding mt-n6">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                
                    <div className="single-benefit">
                        <img src="assets/images/icon/icon-1.png" alt="Icon"/>
                        <h3 className="title">Free Shipping</h3>
                        <p>Get 10% cash back, free shipping, free returns, and more at 1000+ top retailers!</p>
                    </div>

                </div>
                <div className="col-lg-4 col-md-6">

                    <div className="single-benefit">
                        <img src="assets/images/icon/icon-2.png" alt="Icon"/>
                        <h3 className="title">Safe Payment</h3>
                        <p>Get 10% cash back, free shipping, free returns, and more at 1000+ top retailers!</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">

                    <div className="single-benefit">
                        <img src="assets/images/icon/icon-3.png" alt="Icon"/>
                        <h3 className="title">Online Support</h3>
                        <p>Get 10% cash back, free shipping, free returns, and more at 1000+ top retailers!</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default TextBanner
