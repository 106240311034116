import React,{useEffect, useState} from 'react'
import configData from "../Config.json";
import axios from 'axios';
import cookies from "js-cookies";
import { Link } from 'react-router-dom';

const CartInfo = () => {

    const [data, setData] = useState([]);
    const [content, setContent] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [stateOptions, setStateOptions] = useState([]);
    const [pincode, setPincode] = useState('');
    const [shippingFlag, setShippingFlag] = useState(false);
    const [shipping, setShipping] =useState([]);


    useEffect(() => {
        
        const fetchData = async () => {
            
            try
            {
                const config = {
                    headers:{
                        Accept: "application/vnd.api+json",
                        Authorization: "Bearer "+cookies.getItem("Token")
                    }
                };

                const response = await axios.get(configData.CommerceURL+'api/orders/'+cookies.getItem("order")+'?include=line_items&fields[orders]=number,skus_count,formatted_payment_method_amount,formatted_subtotal_amount,formatted_discount_amount,formatted_shipping_amount,formatted_total_tax_amount,formatted_gift_card_amount,formatted_total_amount_with_taxes,line_items&fields[line_items]=item_type,image_url,name,sku_code,formatted_unit_amount,quantity,formatted_total_amount',config);
                console.log(response.data);

                setData(response.data.data.attributes)
                setContent(response.data.included)
            }
            catch(e)
            {
                console.log("error in axios");
            }
        };
        
        fetchData();
        
    },[])

    useEffect(() => {
        
        const fetchData = async () => {
            
            if(shippingFlag)
            {
                try
                {
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };

                    const response = await axios.get(configData.CommerceURL+'api/orders/'+cookies.getItem("order")+'/shipments?include=available_shipping_methods',config);
                    console.log("start shipping");
                    console.log(response.data.data);
                    console.log("end shipping");

                    response.data.included.map( async(itm,key)=>{
                        let id=itm.id;
                        let name=itm.attributes.name;
                        let price=itm.attributes.formatted_price_amount_for_shipment
                        let link=itm.relationships.delivery_lead_time_for_shipment.links.related;

                        var responses=await axios.get(link,config)
                        console.log(responses.data);
                        const value= {shippingid: response.data.data[0].id, id: id, name: name, price: price, min_days: responses.data.data.attributes.min_days, max_days: responses.data.data.attributes.max_days};
                        setShipping((prevValues) => [...prevValues, value]);
        
                    });
                }
                catch(e)
                {
                    console.log("error in axios");
                }
            }
        };
        
        fetchData();
        
    },[shippingFlag])

    const incrementQuantity = async(cart_id,quantity)=>{
        const data={
            "data": {
              "id": cart_id,
              "type": "line_items",
              "attributes": {
                "quantity": quantity+1
              }
            }
        };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        const response = await axios.patch(configData.CommerceURL+'api/line_items/'+cart_id,data,config)
       
        if(response.status===200)
        {
            window.location.reload();
        }
        else
        {
            alert("Cant Update Cart Item "+cart_id);
        }
    }

    const decrementQuantity= async(cart_id,quantity)=>{
        if(quantity-1===0)
        {
            this.deleteCartItem(cart_id);
        }
        else
        {
            const data={
                "data": {
                "id": cart_id,
                "type": "line_items",
                "attributes": {
                    "quantity": quantity-1
                }
                }
            };
            const config = {
                headers:{
                    Accept: "application/vnd.api+json",
                    'Content-Type': "application/vnd.api+json",
                    Authorization: "Bearer "+cookies.getItem("Token")
                }
            };
            const response = await axios.patch(configData.CommerceURL+'api/line_items/'+cart_id,data,config)
            if(response.status===200)
            {
                window.location.reload();
            }
            else
            {
                alert("Cant Update Cart Item "+cart_id);
            }
        }
    }

    const deleteCartItem= async(cart_id)=>{
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
          };
        const response = await axios.delete(configData.CommerceURL+'api/line_items/'+cart_id,config)
        if(response.status===204)
        {
            alert("Deleted Cart Item "+cart_id);
            window.location.reload();
        }
        else
        {
            alert("Cant Delete Cart Item "+cart_id);
        }
    }

    const clearCart = async() =>{
        content.map( async(item,key)=>{
            const config = {
                headers:{
                    Accept: "application/vnd.api+json",
                    'Content-Type': "application/vnd.api+json",
                    Authorization: "Bearer "+cookies.getItem("Token")
                }
              };
            const response = await axios.delete(configData.CommerceURL+'api/line_items/'+item.id,config)
            if(response.status===204)
            {
                console.log("Deleted Cart Item "+item.id);
            }
            else
            {
                console.log("Cant Delete Cart Item "+item.id);
            }
        });
    }

    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setSelectedCountry(selectedCountry);

        const fetchedStateOptions = fetchStateOptions(selectedCountry);
        setStateOptions(fetchedStateOptions);
    };

    const fetchStateOptions = (country) => {
        const stateOptionsMap = {
          usa: [
            { code: 'ny', name: 'New York' },
            { code: 'ca', name: 'California' },

          ],
          uk: [
            { code: 'ldn', name: 'London' },
            { code: 'mcr', name: 'Manchester' },
          ],
          canada: [
            { code: 'on', name: 'Ontario' },
            { code: 'qc', name: 'Quebec' },
          ],
        };
    
        return stateOptionsMap[country] || [];
    };

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setSelectedState(selectedState);
    }

    const handlePincodeChange = (event) => {
        const pincode = event.target.value;
        setPincode(pincode);
    }

    const calculateShipping = async() => {
        const data={
            "data": {
              "type": "addresses",
              "attributes": {
                "zip_code": pincode,
                "state_code": selectedState,
                "country_code": selectedCountry,
              }
            }
          };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        var response = await axios.post(configData.CommerceURL+'api/addresses',data,config)

        console.log(response.data);

        const address_data={
            "data": {
                "type": "orders",
                "id": cookies.getItem("order"),
                "relationships": {
                "shipping_address": {
                    "data": {
                    "type": "addresses",
                    "id": response.data.data.id
                    }
                }
                }
            }
        };
        const address_config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        var response = await axios.patch(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),address_data,address_config)

        setShippingFlag(true);
    }

    const hasBasketNonEmpty = (content) =>{
        if(content)
            return true;
        else
            return false;
    }

  return (
    hasBasketNonEmpty(content) ? (
     
    <div className="section section-padding">
        <div className="container">
            <div className="cart-wrapper">

                <div className="cart-table table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="product-thumb">Image</th>
                                <th className="product-info">product Information</th>
                                <th className="product-quantity">Quantity</th>
                                <th className="product-total-price">Total Price</th>
                                <th className="product-action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {content.map((item,key)=>{
                            if(item.attributes.sku_code!==null)
                            {
                                return (
                                    <tr key={key}>
                                <td className="product-thumb">
                                    <img src={item.attributes.image_url} alt=""/>
                                </td>
                                <td className="product-info">
                                    <h6 className="name"><a href="product-details.html">{item.attributes.name}</a></h6>
                                    <div className="product-prices">
                                        <span className="sale-price">{item.attributes.formatted_unit_amount}</span>
                                    </div>
                                </td>
                                <td className="quantity">
                                    <div className="product-quantity d-inline-flex">
                                        <button type="button" className="sub" onClick={()=>decrementQuantity(item.id,item.attributes.quantity)}>-</button>
                                        <input type="text" value={item.attributes.quantity} />
                                        <button type="button" className="add" onClick={()=>incrementQuantity(item.id,item.attributes.quantity)}>+</button>
                                    </div>
                                </td>
                                <td className="product-total-price">
                                    <span className="price">{item.attributes.formatted_total_amount}</span>
                                </td>
                                <td className="product-action">
                                    <button className="remove" onClick={()=>deleteCartItem(item.id)} ><i className="pe-7s-trash"></i></button>
                                </td>
                            </tr>
                                )
                            }
                        })}    
                            
                           
                            
                        </tbody>
                    </table>
                </div>

                <div className="cart-btn">
                    <div className="left-btn">
                        <Link to='/shop' className="btn btn-dark btn-hover-primary">Continue Shopping</Link>
                    </div>
                    <div className="right-btn">
                        <button className="btn btn-outline-dark" onClick={()=>clearCart()}>Clear Cart</button>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-lg-4">

                    <div className="cart-shipping">
                        <div className="cart-title">
                            <h4 className="title">Calculate Shipping</h4>
                            <p>Estimate your shipping fee *</p>
                        </div>
                        <div className="cart-form">
                            <p>Calculate shipping</p>
                            <form action="#">
                                <div className="single-select2">
                                    <div className="form-select2">
                                        <select className="select2" value={selectedCountry} onChange={handleCountryChange} style={{height: '40px',borderColor: '#d7d7d7',color: '#6c757d',paddingLeft: '10px',fontSize: '14px'}}>
                                            <option value="0">Select a Country *</option>
                                            <option value="usa">USA</option>
                                            <option value="uk">UK</option>
                                            <option value="canada">Canada</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="single-select2">
                                    <div className="form-select2">
                                        <select className="select2" value={selectedState} onChange={handleStateChange} style={{height: '40px',borderColor: '#d7d7d7',color: '#6c757d',paddingLeft: '10px',fontSize: '14px'}}>
                                            <option value="">Select a state</option>
                                            {stateOptions.map((state) => (
                                            <option key={state.code} value={state.code}>
                                                {state.name}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="single-form">
                                    <input className="form-control" value={pincode} onChange={handlePincodeChange} type="text" placeholder="Postcode/ziip"/>
                                </div>
                                <div className="single-form">
                                    <button className="btn btn-dark btn-hover-primary">Update totals</button>
                                </div>
                                {/* onClick={()=>calculateShipping()} */}
                            </form>
                        </div>
                    </div>

                </div>
                <div className="col-lg-4">

                    <div className="cart-shipping">
                        <div className="cart-title">
                            <h4 className="title">Coupon Code</h4>
                            <p>Enter your coupon code if you have one.</p>
                        </div>
                        <div className="cart-form">
                            <form action="#">
                                <div className="single-form">
                                    <input className="form-control" type="text" placeholder="Enter your coupon code.."/>
                                </div>
                                <div className="single-form">
                                    <button className="btn btn-dark btn-hover-primary">Apply Coupon</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="cart-totals">
                        <div className="cart-title">
                            <h4 className="title">Cart totals</h4>
                        </div>
                        <div className="cart-total-table">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="value">Subtotal</p>
                                        </td>
                                        <td>
                                            <p className="price">{data['formatted_subtotal_amount']}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="value">Discount Amount</p>
                                        </td>
                                        <td>
                                            <p className="price">{data['formatted_discount_amount']}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="value">Gift Card Amount</p>
                                        </td>
                                        <td>
                                            <p className="price">{data['formatted_gift_card_amount']}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="value">Payment Amount</p>
                                        </td>
                                        <td>
                                            <p className="price">{data['formatted_payment_method_amount']}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="value">Total Tax Amount</p>
                                        </td>
                                        <td>
                                            <p className="price">{data['formatted_total_tax_amount']}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="value">Total</p>
                                        </td>
                                        <td>
                                            <p className="price">{data['formatted_total_amount_with_taxes']}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="cart-total-btn">
                            <Link to='/checkout' className="btn btn-dark btn-hover-primary btn-block">Proceed To Checkout</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    ):(

        <div className="section section-padding">
            <div className="container">
                <div className="cart-wrapper">
                    <div className="empty-cart text-center">
                        <h2 className="empty-cart-title">There are no more items in your cart</h2>
                        <div className="empty-cart-img">
                            <img src="assets/images/cart.png" alt="Cart"/>
                        </div>
                        <p>Your cart is currently empty!</p>
                        <Link to='/shop' className="btn btn-primary btn-hover-dark"><i className="fa fa-angle-left"></i> Continue browsing</Link>
                    </div>
                </div>
            </div>
        </div>
    )
  )
}

export default CartInfo
