import React from 'react'
import { Link } from 'react-router-dom';

function SignupInfo() {
  return (
    <div class="section section-padding mt-n1">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="login-register-wrapper">
                        <h4 class="title">Create New Account</h4>
                        <p>Already have an account? <Link to='/signin'>Log in instead!</Link></p>
                        <form action="#">
                            <div class="single-form">
                                <input type="text" placeholder="First Name"/>
                            </div>
                            <div class="single-form">
                                <input type="text" placeholder="Last Name"/>
                            </div>
                            <div class="single-form">
                                <input type="text" placeholder="Email Address *"/>
                            </div>
                            <div class="single-form">
                                <input type="text" placeholder="Username *"/>
                            </div>
                            <div class="single-form">
                                <input type="password" placeholder="Password"/>
                            </div>
                            <div class="single-form">
                                <input type="password" placeholder="Confirm Password"/>
                            </div>
                            <div class="single-form">
                                <input type="checkbox" id="receive"/>
                                <label htmlFor="receive"> <span></span> Receive Offers From Our Partners</label>
                            </div>
                            <div class="single-form">
                                <input type="checkbox" id="newsletter"/>
                                <label htmlFor="newsletter"> <span></span> Sign Up For Our Newsletter <br/> Subscribe To Our Newsletters Now And Stay Up-To-Date With New Collections, The Latest Lookbooks And Exclusive Offers.</label>
                            </div>
                            <div class="single-form">
                                <button class="btn btn-primary btn-hover-dark">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SignupInfo
