import React from 'react'
import { Link } from 'react-router-dom';

import { Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.css'
import '../Components/style.css'

import slider1 from '../Components/images/slider/slider-item-1.png'
import slider2 from '../Components/images/slider/slider-item-2.png'
import slider3 from '../Components/images/slider/slider-item-3.png'


function Slider() {
  return (
    <div className="section slider-section">

    <div className="slider-shape"></div>

    <div className="container">
        <div className="slider-active">
            <div className="swiper-container">
                <Swiper modules={[Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                    el: '#banner',
                    clickable: true
                }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')} className="swiper-wrapper">
                    <SwiperSlide>
                    <div className="single-slider swiper-slide">
                        <div className="slider-content">
                            <h2 className="title">New Stylish <br/> Decore Furniture</h2>
                            <p>Unique Furniture Style Design for Your Family</p>
                            <Link to='/shop' className="btn btn-primary btn-hover-dark">Shop now</Link>
                        </div>
                        <div className="slider-images">
                            <img src={slider1} alt="Slider"/>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="single-slider swiper-slide">
                        <div className="slider-content">
                            <h2 className="title">New Stylish <br/> Decore Furniture</h2>
                            <p>Unique Furniture Style Design for Your Family</p>
                            <Link to='/shop' className="btn btn-primary btn-hover-dark">Shop now</Link>
                        </div>
                        <div className="slider-images">
                            <img src={slider2} alt="Slider"/>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="single-slider swiper-slide">
                        <div className="slider-content">
                            <h2 className="title">New Stylish <br/> Decore Furniture</h2>
                            <p>Unique Furniture Style Design for Your Family</p>
                            <Link to='/shop' className="btn btn-primary btn-hover-dark">Shop now</Link>
                        </div>
                        <div className="slider-images">
                            <img src={slider3} alt="Slider"/>
                        </div>
                    </div>
                    </SwiperSlide>
                    
                </Swiper>
                {/* <div class="swiper-pagination" id='banner'></div> */}
                
            </div>
        </div>
    </div>
    </div>
  )
}

export default Slider
