import React from 'react'
import { Link } from 'react-router-dom';
import banner from '../Components/images/page-banner.jpg'

function SignupBanner() {
  return (
    <div class="section page-banner-section" style={{ backgroundImage: `url(${banner})`}}>
        <div class="container">

            <div class="page-banner-content">
            <h2 class="title">Register</h2>

            <ul class="breadcrumb">
                <li><Link to='/home'>Home</Link></li>
                <li class="active">Register</li>
            </ul>
            </div>

        </div>
    </div>
  )
}

export default SignupBanner
