import React from 'react'
import { Link } from 'react-router-dom';
import banner from '../Components/images/page-banner.jpg'

function DetailBanner() {
  return (
    <div className="section page-banner-section" style={{ backgroundImage: `url(${banner})`}}>
        <div className="container">

            <div className="page-banner-content">
                <h2 className="title">Product Details</h2>

                <ul className="breadcrumb">
                    <li><Link to='/home'>Home</Link></li>
                    <li className="active">Product Details</li>
                </ul>
            </div>

        </div>
    </div>
  )
}

export default DetailBanner
