import React from 'react'

function OffCanvas() {
  return (
    <div className="off-canvas-box">
        <div className="canvas-action">
            <a className="action" href="compare.html"><i className="icon-sliders"></i> Compare <span className="action-num">(3)</span></a>
            <a className="action" href="wishlist.html"><i className="icon-heart"></i> Wishlist <span className="action-num">(3)</span></a>
        </div>

        <div className="canvas-close-bar">
            <span>Menu</span>
            <a className="menu-close" href="javascript:;"><i className="pe-7s-angle-left"></i></a>
        </div>

        <div className="canvas-menu">
            <nav>
                <ul className="nav-menu">
                    <li><a href="contact.html">Home</a></li>
                    <li><a href="contact.html">About</a></li>
                    <li><a href="contact.html">Shop</a></li>
                    <li><a href="contact.html">Blogs</a></li>
                    <li><a href="contact.html">Contact</a></li>
                </ul>

            </nav>
        </div>
    </div>
  )
}

export default OffCanvas
