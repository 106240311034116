import React from 'react'

function AboutInfo() {
  return (
    <div className="section section-padding-02">
        <div className="container">
            <div className="history-content text-center">

                <div className="section-title-03">
                    <h6 className="sub-title">Our History</h6>
                    <h2 className="title">Furbar Furniture Shop</h2>
                </div>

                <p>Lorem ipsum dolor sit amet, consectet adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nullaotho pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natusxcl error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dictapteo sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem drt sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

            </div>

            <div className="history-icon text-center">
                <img src="assets/images/icon/icon-5.jpg" alt="Icon"/>
            </div>

        </div>
    </div>
  )
}

export default AboutInfo
