import React,{useEffect, useState} from 'react'
import configData from "../Config.json";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cookies from "js-cookies";

const DetailsInfo=()=> {

    const [data, setData] = useState([]);
    const [price, setPrice] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sku = queryParams.get('sku');

    const currency = useSelector((state) => state.data.currency);

    useEffect(() => {
        
        const fetchData = async () => {
            
            try
            {
                const config = {
                    headers:{
                        Accept: "application/vnd.api+json",
                        Authorization: "Bearer "+cookies.getItem("Token")
                    }
                };
                

                const response = await axios.get(configData.CommerceURL+'api/skus/'+sku+'?include=prices',config);
                console.log(response.data);
                console.log(response.data.data)

                setData(response.data.data.attributes)
                setPrice(response.data.included)
                setProduct(response.data.data.id);
            }
            catch(e)
            {
                console.log("error in axios");
            }
        };
        
        fetchData();
        
    },[sku])

    useEffect(() => {
        
        const fetchData = async () => {
            if(cookies.getItem("order")==null)
            {
                console.log('order use effect executed');
                try
                {   
                    const body = {
                        data: { 
                            type: "orders",
                            attributes:{
                                metadata:{
                                    shipping:false,
                                    shipping_id:'',
                                    payment_id:''
                                }
                            }
                        },
                        relationship:{
                            "market": cookies.getItem("market")
                        }
                    };
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    const response = await axios.post(configData.CommerceURL+'api/orders',body,config)
                    
                    const secure = window.location.protocol === 'https'
                    cookies.setItem("order", response.data.data.id, undefined, "/", undefined, secure)
                    
                }
                catch(e)
                {
                    console.log("error in axios");
                }
            }
        };
        
        fetchData();
        
    },[cookies.getItem("order")])

    const addCart = async(product_id,name,image)=>{
        const body = {
            "data": {
                "type": "line_items",
                "attributes": {
                "quantity": quantity,
                "name": name,
                "image_url": image,
                "_update_quantity": true
                },
                "relationships": {
                "order": {
                    "data": {
                    "type": "orders",
                    "id": cookies.getItem("order")
                    }
                },
                "item": {
                    "data": {
                    "type": "skus",
                    "id": product_id
                    }
                }
                }
            }
        };
        const config = {
            headers:{
                Accept: "application/vnd.api+json",
                'Content-Type': "application/vnd.api+json",
                Authorization: "Bearer "+cookies.getItem("Token")
            }
        };
        const response = await axios.post(configData.CommerceURL+'api/line_items',body,config)
        if(response.status == 201)
        console.log("Product Added to Cart");                                                                                                                                                                                                  
    }

    let item_price,compare_price
    const foundElement = price.find(it =>  it.attributes.currency_code===currency);
    if(foundElement!=null)
    {
        item_price=foundElement.attributes.formatted_amount;
        compare_price=foundElement.attributes.formatted_compare_at_amount;
    }


    const decrement = (quantity) => {
        if((quantity-1) === 0)
        {
            setQuantity(quantity);
        }
        else
        {
            setQuantity(quantity-1);
        }
    }
    

  return (
    <div className="section section-padding-02 mt-n10" style={{paddingBottom:"40px"}}>
        <div className="container">

            <div className="row">
                <div className="col-lg-6">
                    
                    <div className="product-details-images">

                        <div className="details-gallery-images">
                            <div className="swiper-container">
                                
  
                                        <div className="single-img zoom">
                                            <img src={data['image_url']} alt="Product Image"/>
                                            <div className="inner-stuff">
                                                <div className="gallery-item" data-src={data['image_url']}>
                                                    <a href="javascript:void(0)">
                                                        <i className="lastudioicon-full-screen"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                
                            </div>
                        </div>

                        

                    </div>

                </div>
                <div className="col-lg-6">

                    <div className="product-details-description">
                        <h4 className="product-name">{data['name']}</h4>
                        <div className="price">
                            
                            <span className="sale-price">{item_price}</span>
                            <span className="old-price">{compare_price}</span>
                        </div>
                       

                       

                        <p>{data['description']}</p>

                        <div className="product-meta">
                            <div className="product-quantity d-inline-flex">
                                <button type="button" className="sub" onClick={()=>decrement(quantity)}>-</button>
                                <input type="text" value={quantity} />
                                <button type="button" className="add" onClick={()=>setQuantity(quantity+1)}>+</button>
                            </div>
                            <div className="meta-action">
                                <button className="btn btn-dark btn-hover-primary"  onClick={()=>addCart(product,data['name'],data['image_url'])}>Add To Cart</button>
                            </div>
                            <div className="meta-action">
                                <a className="action" href="#"><i className="pe-7s-like"></i></a>
                            </div>
                            <div className="meta-action">
                                <a className="action" href="#"><i className="pe-7s-shuffle"></i></a>
                            </div>
                        </div>

                        <div className="product-info">
                            <div className="single-info">
                                <span className="lable">SKU:</span>
                                <span className="value">{data['code']}</span>
                            </div>
                            
                            <div className="single-info">
                                <span className="lable">Share:</span>
                                <ul className="social">
                                    <li><a href="#"><i className="fa fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                                    <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default DetailsInfo
