import React from 'react'

function ContactInfo() {
  return (
    <div className="section section-padding">
        <div className="container">

            <div className="contact-wrapper">
                <div className="row gx-0">
                    <div className="col-lg-4">
                        <div className="contact-info">
                            <h2 className="title">Contact Info</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed eiusmod</p>

                            <div className="contact-info-items">

                                <div className="single-contact-info">
                                    <div className="info-icon">
                                        <i className="pe-7s-call"></i>
                                    </div>
                                    <div className="info-content">
                                        <p><a href="tel:+00125458222">+00 125 458 222</a></p>
                                    </div>
                                </div>

                                <div className="single-contact-info">
                                    <div className="info-icon">
                                        <i className="pe-7s-mail"></i>
                                    </div>
                                    <div className="info-content">
                                        <p><a href="mailto:demo@yourmail.com">demo@yourmail.com</a></p>
                                    </div>
                                </div>

                                <div className="single-contact-info">
                                    <div className="info-icon">
                                        <i className="pe-7s-map-marker"></i>
                                    </div>
                                    <div className="info-content">
                                        <p>Your Address Goese Here</p>
                                    </div>
                                </div>

                            </div>

                            <ul className="social">
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fa fa-tumblr"></i></a></li>
                                <li><a href="#"><i className="fa fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                            </ul>

                            <img src="assets/images/contact-info.png" alt="Contact-info"/>

                        </div>
                    </div>
                    <div className="col-lg-8">

                        <div className="contact-form">
                            <form id="contact-form" action="assets/php/contact.php" method="post">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="text" name="name" placeholder="Name*"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="email" name="email" placeholder="Email*"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="text" name="subject" placeholder="Subject"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="text" name="phone" placeholder="Phone No"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-form">
                                            <textarea name="message" placeholder="Write your comments here"></textarea>
                                        </div>
                                    </div>
                                    <p className="form-message"></p>
                                    <div className="col-md-12">
                                        <div className="single-form">
                                            <button type="submit" className="btn btn-dark btn-hover-primary">Submit Review</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default ContactInfo
