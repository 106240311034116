import React from 'react'
import { Link } from 'react-router-dom';

const SigninInfo = () => {
  return (
    <div class="section section-padding mt-n1">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">

                    <div class="login-register-wrapper">
                        <h4 class="title">Login to Your Account</h4>
                        <form action="#">
                            <div class="single-form">
                                <input type="text" placeholder="Username or email *"/>
                            </div>
                            <div class="single-form">
                                <input type="password" placeholder="Password"/>
                            </div>
                            <div class="single-form">
                                <input type="checkbox" id="remember"/>
                                <label htmlFor="remember"><span></span> Remember me</label>
                            </div>
                            <div class="single-form">
                                <button class="btn btn-primary btn-hover-dark">Login</button>
                            </div>
                        </form>
                        <p><a href="#">Lost your password?</a></p>
                        <p>No account? <Link to='/signup'>Create one here.</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SigninInfo
