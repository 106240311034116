import React from 'react'

import Header from './Header';
import HeaderMobile from './HeaderMobile';
import OffCanvas from './OffCanvas';
import Slider from './Slider';
import Banner from './Banner';
import NewProducts from './NewProducts';
import SubBanner from './SubBanner';
import BestSellers from './BestSellers';
import TextBanner from './TextBanner';
import Footer from './Footer';
import MoveTop from './MoveTop'

function Home() {
  return (
    <React.Fragment>
        <Header/>
        {/* <HeaderMobile/>
        <OffCanvas/> */}
        <div className="menu-overlay"></div> 
        <Slider/>
        <Banner/>
        <NewProducts/>
        <SubBanner/>
        <BestSellers/>
        <TextBanner/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default Home
