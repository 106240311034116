import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import cookies from "js-cookies";
import configData from "../Config.json";
import axios from 'axios';

const Country = ()=> {

    const navigate = useNavigate();

    const [country, setCountry] = useState();
    const [market, setMarket] = useState();
    const [currency, setCurrency] = useState();

    useEffect(() => {
        
        const fetchData = async () => {
            if(country!=null)
            {
                try
                {
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            'Content-Type': "application/vnd.api+json",
                        }
                    };
                    const body = {
                        'grant_type': 'client_credentials',
                        'scope':'market:'+market,
                        'client_id': configData.ClientId,
                        'client_secret': configData.ClientSecret
                    };

                    const response = await axios.post(configData.CommerceURL+'oauth/token',body,config);
                    console.log(response.data);

                    const secure = window.location.protocol === 'https'
                    cookies.setItem("country", country, undefined, "/", undefined, secure)
                    cookies.setItem("market", market, undefined, "/", undefined, secure)
                    cookies.setItem("currency", currency, undefined, "/", undefined, secure)
                    cookies.setItem("Token", response.data.access_token, response.data.expires_in, "/", undefined, secure)

                    navigate('/home'); 
                }
                catch(e)
                {
                    console.log("error in axios");
                }
            }
        };
        
        fetchData();
        
    },[country])


    function changeCountry(country,market,currency)
    {
        if(country!=null && market!=null && currency!=null)
        {
            setCountry(country);
            setMarket(market);
            setCurrency(currency);          
        }
    }

    return (
        <div className="container" id="content">
            <div className="row h-100 mt-5">
                <main className="col-md-12 text-center align-middle">
                Country {cookies.getItem("country")}<br/>
                Market {cookies.getItem("market")}<br/>
                Currency {cookies.getItem("currency")} <br/>
                <button className={cookies.getItem("country")==='USA'?'btn btn-success':'btn btn-primary'} onClick={()=>changeCountry('USA','13095','USD')}>USA</button>&nbsp;&nbsp;&nbsp;
                <button className={cookies.getItem("country")==='UK'?'btn btn-success':'btn btn-primary'} onClick={()=>changeCountry('UK','13096','GBP')}>United Kingdom</button>&nbsp;&nbsp;&nbsp;
                <button className={cookies.getItem("country")==='Europe'?'btn btn-success':'btn btn-primary'} onClick={()=>changeCountry('Europe','13094','EUR')}>Europe</button>
                </main>
            </div>
        </div>
    )
}

export default Country;
