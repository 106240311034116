import React from 'react'
import Header from './Header'
import HeaderMobile from './HeaderMobile'
import OffCanvas from './OffCanvas'
import Footer from './Footer'
import MoveTop from './MoveTop'
import SuccessInfo from './SuccessInfo'

function SuccessPage() {
  return (
    <React.Fragment>
        <Header/>
        <HeaderMobile/>
        <OffCanvas/>
        <div className="menu-overlay"></div> 
        <SuccessInfo/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default SuccessPage
