import React,{useEffect, useState} from 'react'
import configData from "../Config.json";
import axios from 'axios';
import cookies from "js-cookies";
import { Link } from 'react-router-dom';
import logo from '../Components/images/logo.png'
import cart from '../Components/images/cart/cart-2.jpg'


const HeaderMobile = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        
        const fetchData = async () => {
            
            try
            {
                const config = {
                    headers:{
                        Accept: "application/vnd.api+json",
                        Authorization: "Bearer "+cookies.getItem("Token")
                    }
                };
                
                const response = await axios.get(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),config);
                console.log(response.data);
                console.log(response.data.data)

                setData(response.data.data.attributes)
            }
            catch(e)
            {
                console.log("error in axios");
            }
        };
        
        fetchData();
        
    },[])

  return (
    <div className="header-mobile section d-lg-none">
        <div className="header-mobile-top header-sticky">
            <div className="container">
                <div className="row row-cols-3 gx-2 align-items-center">
                    <div className="col">
                        <div className="header-toggle">
                            <button className="mobile-menu-open">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div className="col">
                        <div className="header-logo text-center">
                            <a href="index.html"><img src={logo} alt="Logo"/></a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="header-meta">
                            <div className="dropdown">
                                <a className="action" href="#!" role="button" data-bs-toggle="dropdown"><i className="pe-7s-user"></i></a>

                                <ul className="dropdown-menu dropdown-profile">
                                    <li><a href="my-account.html">My Account</a></li>
                                    <li><a href="checkout.html">Checkout</a></li>
                                    <li><a href="login.html">Sign In</a></li>
                                </ul>
                            </div>
                            <Link className="action" to='/cart'>
                                <i className="pe-7s-shopbag"></i>
                                <span className="number">{data['skus_count']}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header-mobile-bottom">
            <div className="container">
                <div className="header-search">
                    <form action="#">
                        <input type="text" placeholder="Enter your search key ... "/>
                        <button><i className="pe-7s-search"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HeaderMobile

