import React from 'react'
import { Link } from 'react-router-dom';
import cart from '../Components/images/cart.png'

function FailureInfo() {
  return (
    <div className="section section-padding" style={{marginTop:'70px'}}>
        <div className="container">
            <div className="cart-wrapper">
                <div className="empty-cart text-center">
                    <h2 className="empty-cart-title">Payment Failed! Please try again later...</h2>
                    <div className="empty-cart-img">
                        <img src={cart} alt="Cart"/>
                    </div>
                    <br/><br/><br/>
                    <Link to="/home" className="btn btn-primary btn-hover-dark"><i className="fa fa-angle-left"></i> Continue browsing</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FailureInfo
