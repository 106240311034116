import React,{useEffect, useState} from 'react'
import configData from "../Config.json";
import axios from 'axios';
import cookies from "js-cookies";
import { Link } from 'react-router-dom';
import logo from '../Components/images/logo.png'
import cart from '../Components/images/cart/cart-2.jpg'

const Header = () => {

    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [mobileHeader, setMobileHeader]= useState(false);

    useEffect(() => {
        
        const fetchData = async () => {
            if(cookies.getItem("order")!=null)
            {
                try
                {
                    const config = {
                        headers:{
                            Accept: "application/vnd.api+json",
                            Authorization: "Bearer "+cookies.getItem("Token")
                        }
                    };
                    
                    const response = await axios.get(configData.CommerceURL+'api/orders/'+cookies.getItem("order"),config);
                    console.log(response.data);
                    console.log(response.data.data)

                    setData(response.data.data.attributes)
                }
                catch(e)
                {
                    console.log("error in axios");
                }
            }
        };
        
        fetchData();
        
    },[cookies.getItem("order")])

    const toggleDropdown = () => {
        console.log(isOpen);
        setIsOpen(!isOpen);
    };

    const toggleHeader = () =>{
        setMobileHeader(!mobileHeader);
    }

  return (
    <>
    <div className="header-area header-sticky d-none d-lg-block">
        <div className="container position-relative">
            <div className="row align-items-center">
                <div className="col-lg-3">
                    <div className="header-logo">
                        <Link to="/"><img src={logo} alt="Logo"/></Link>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="header-menu">
                        <ul className="nav-menu">
                            <li><Link to="/home">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li>
                                <Link to='/shop' className="menu-title">Shop</Link>

                                <ul className="sub-menu">
                                    <li><Link to="/shop?category=Backpack">Backpack</Link></li>
                                    <li><Link to="/shop?category=Cap">Cap</Link></li>
                                    <li><Link to="/shop?category=Beanie">Beanie</Link></li>
                                    <li><Link to="/shop?category=Baby">Baby Dress</Link></li>
                                    <li><Link to="/shop?category=Towel">Towel</Link></li>
                                    <li><Link to="/shop?category=Top">Top</Link></li>
                                    <li><Link to="/shop?category=Bag">Bags</Link></li>
                                    <li><Link to="/shop?category=Mug">Mugs</Link></li>
                                    <li><Link to="/shop?category=Mask">Face Mask</Link></li>
                                    <li><Link to="/shop?category=Flops">Flip Flops</Link></li>
                                    <li><Link to="/shop?category=Hoodie">Hoodies</Link></li>
                                    <li><Link to="/shop?category=Sleeve">Sleeves</Link></li>
                                    <li><Link to="/shop?category=Notebook">Notebook</Link></li>
                                    <li><Link to="/shop?category=Pillow">Pillows</Link></li>
                                    <li><Link to="/shop?category=Polo">Polos</Link></li>
                                    <li><Link to="/shop?category=Shirt">Shirts</Link></li>
                                    <li><Link to="/shop?category=Socks">Socks</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>

                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="header-meta">

                        <div className="dropdown">
                            <a className="action" href="#!" role="button" data-bs-toggle="dropdown"> <i className="pe-7s-search"></i> </a>

                            <div className="dropdown-menu dropdown-search">
                                <div className="header-search">
                                    <form action="#">
                                        <input type="text" placeholder="Enter your search key ... "/>
                                        <button><i className="pe-7s-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown">
                            <a className="action" href="#!" role="button" onClick={toggleDropdown}><i className="pe-7s-user"></i></a>
                            
                            {isOpen && (
                            <ul className="dropdown-menu dropdown-profile" style={{display: 'block',marginRight: '0px',marginLeft: '0px',position: 'absolute',inset: '0px auto auto 0px',transform: 'translate(10.4px, 38.4px)'}}>
                                <li><a href="my-account.html">My Account</a></li>
                                <li><a href="checkout.html">Checkout</a></li>
                                <li><Link to='/signin'>Sign In</Link></li>
                            </ul>
                            )}
                        </div>
                        <a className="action" href="wishlist.html"><i className="pe-7s-like"></i></a>

                        <div className="dropdown">
                            <Link className="action" to='/cart' role="button" data-bs-toggle="dropdown">
                                <i className="pe-7s-shopbag"></i>
                                <span className="number">{data['skus_count']}</span>
                            </Link>

                            <div className="dropdown-menu dropdown-cart">
                                <div className="cart-content" id="cart-content">
                                    <ul>
                                        <li>
                                            <div className="single-cart-item">
                                                <div className="cart-thumb">
                                                    <img src={cart} alt="Cart"/>
                                                    <span className="product-quantity">1x</span>
                                                </div>
                                                <div className="cart-item-content">
                                                    <h6 className="product-name">High quality vase bottle</h6>
                                                    <span className="product-price">$19.12</span>
                                                    <div className="attributes-content">
                                                        <span><strong>Color:</strong> White </span>
                                                    </div>
                                                    <a className="cart-remove" href="#!"><i className="las la-times"></i></a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-cart-item">
                                                <div className="cart-thumb">
                                                    <img src={cart} alt="Cart"/>
                                                    <span className="product-quantity">1x</span>
                                                </div>
                                                <div className="cart-item-content">
                                                    <h6 className="product-name">Living & Bedroom Chair</h6>
                                                    <span className="product-price">$19.12</span>
                                                    <div className="attributes-content">
                                                        <span><strong>Color:</strong> White </span>
                                                    </div>
                                                    <a className="cart-remove" href="#!"><i className="las la-times"></i></a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-cart-item">
                                                <div className="cart-thumb">
                                                    <img src={cart} alt="Cart"/>
                                                    <span className="product-quantity">1x</span>
                                                </div>
                                                <div className="cart-item-content">
                                                    <h6 className="product-name">Herman Arm Grey Chair</h6>
                                                    <span className="product-price">$19.12</span>
                                                    <div className="attributes-content">
                                                        <span><strong>Color:</strong> White </span>
                                                    </div>
                                                    <a className="cart-remove" href="#!"><i className="las la-times"></i></a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="cart-price">
                                    <div className="cart-subtotals">
                                        <div className="price-inline">
                                            <span className="label">Subtotal</span>
                                            <span className="value">$42.70</span>
                                        </div>
                                        <div className="price-inline">
                                            <span className="label">Shipping</span>
                                            <span className="value">$7.00</span>
                                        </div>
                                        <div className="price-inline">
                                            <span className="label">Taxes</span>
                                            <span className="value">$0.00</span>
                                        </div>
                                    </div>
                                    <div className="cart-total">
                                        <div className="price-inline">
                                            <span className="label">Total</span>
                                            <span className="value">$49.70</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-btn">
                                    <a href="checkout.html" className="btn btn-dark btn-hover-primary d-block">Checkout</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="header-mobile section d-lg-none">
        <div className="header-mobile-top header-sticky">
            <div className="container">
                <div className="row row-cols-3 gx-2 align-items-center">
                    <div className="col">
                        <div className="header-toggle">
                            <button className="mobile-menu-open" onClick={toggleHeader}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div className="col">
                        <div className="header-logo text-center">
                            <Link to='/'><img src={logo} alt="Logo"/></Link>
                        </div>
                    </div>
                    <div className="col">
                        <div className="header-meta">
                            <div className="dropdown">
                                <a className="action" href="#!" role="button" data-bs-toggle="dropdown"><i className="pe-7s-user"></i></a>

                                <ul className="dropdown-menu dropdown-profile">
                                    <li><a href="my-account.html">My Account</a></li>
                                    <li><a href="checkout.html">Checkout</a></li>
                                    <li><a href="login.html">Sign In</a></li>
                                </ul>
                            </div>
                            <Link className="action" to='/cart'>
                                <i className="pe-7s-shopbag"></i>
                                <span className="number">{data['skus_count']}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header-mobile-bottom">
            <div className="container">
                <div className="header-search">
                    <form action="#">
                        <input type="text" placeholder="Enter your search key ... "/>
                        <button><i className="pe-7s-search"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>


    <div className={mobileHeader?'off-canvas-box open':'off-canvas-box'}>

        <div className="canvas-action">
            <a className="action" href="wishlist.html"><i className="icon-heart"></i> Cart <span className="action-num">({data['skus_count']})</span></a>
        </div>

        <div className="canvas-close-bar">
            <span>Menu</span>
            <a className="menu-close" onClick={toggleHeader} ><i className="pe-7s-angle-left"></i></a>
        </div>

        <div className="canvas-menu">
            <nav>
                <ul className="nav-menu">
                    <li><Link to="/home">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li>
                        <Link to='/shop' className="menu-title">Shop</Link>

                        <ul className="menu-item" >
                            <li><Link to="/shop?category=Backpack">Backpack</Link></li>
                            <li><Link to="/shop?category=Cap">Cap</Link></li>
                            <li><Link to="/shop?category=Beanie">Beanie</Link></li>
                            <li><Link to="/shop?category=Baby">Baby Dress</Link></li>
                            <li><Link to="/shop?category=Towel">Towel</Link></li>
                            <li><Link to="/shop?category=Top">Top</Link></li>
                            <li><Link to="/shop?category=Bag">Bags</Link></li>
                            <li><Link to="/shop?category=Mug">Mugs</Link></li>
                            <li><Link to="/shop?category=Mask">Face Mask</Link></li>
                            <li><Link to="/shop?category=Flops">Flip Flops</Link></li>
                            <li><Link to="/shop?category=Hoodie">Hoodies</Link></li>
                            <li><Link to="/shop?category=Sleeve">Sleeves</Link></li>
                            <li><Link to="/shop?category=Notebook">Notebook</Link></li>
                            <li><Link to="/shop?category=Pillow">Pillows</Link></li>
                            <li><Link to="/shop?category=Polo">Polos</Link></li>
                            <li><Link to="/shop?category=Shirt">Shirts</Link></li>
                            <li><Link to="/shop?category=Socks">Socks</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>

            </nav>
        </div>
    </div>
    </>
    
  )
}

export default Header
