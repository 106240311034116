import React from 'react'
import Header from './Header'
import HeaderMobile from './HeaderMobile'
import OffCanvas from './OffCanvas'
import Footer from './Footer'
import MoveTop from './MoveTop'
import CheckoutBanner from './CheckoutBanner'
import CheckoutInfo from './CheckoutInfo'

function CheckoutPage() {
  return (
    <React.Fragment>
        <Header/>
        {/* <HeaderMobile/>
        <OffCanvas/> */}
        <div className="menu-overlay"></div> 
        <CheckoutBanner/>
        <CheckoutInfo/>
        <Footer/>
        <MoveTop/>
    </React.Fragment>
  )
}

export default CheckoutPage
